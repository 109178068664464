/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * VCRWebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./Configuration";

const BASE_PATH = "http://cms.visitczechia.com/cms".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: string = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    password?: string;
}

/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    token?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthResponse
     */
    success?: boolean;
}

/**
 * 
 * @export
 * @interface AutomatedTranslationVoting
 */
export interface AutomatedTranslationVoting {
    /**
     * 
     * @type {string}
     * @memberof AutomatedTranslationVoting
     */
    nodeGUID: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedTranslationVoting
     */
    documentCulture: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedTranslationVoting
     */
    status: AutomatedTranslationVoting.StatusEnum;
}

/**
 * @export
 * @namespace AutomatedTranslationVoting
 */
export namespace AutomatedTranslationVoting {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Dislike = <any> 'Dislike',
        Like = <any> 'Like'
    }
}

/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    theme?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    imageText?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    imageAspectRatio?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof Banner
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Banner
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Banner
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Banner
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Banner
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof Banner
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof Banner
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Banner
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BannerStatistics
 */
export interface BannerStatistics {
    /**
     * 
     * @type {string}
     * @memberof BannerStatistics
     */
    bannerGUID: string;
    /**
     * 
     * @type {string}
     * @memberof BannerStatistics
     */
    bannerAction: string;
}

/**
 * 
 * @export
 * @interface BaseNavigationItem
 */
export interface BaseNavigationItem {
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    cssClass?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseNavigationItem
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseNavigationItem
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseNavigationItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseNavigationItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseNavigationItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseNavigationItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BaseNavigationItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof BaseNavigationItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseNavigationItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePage
 */
export interface BasePage {
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    imageFilterType?: BasePage.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof BasePage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof BasePage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof BasePage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof BasePage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasePage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof BasePage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePage
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof BasePage
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof BasePage
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BasePage
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    cultureCode?: string;
}

/**
 * @export
 * @namespace BasePage
 */
export namespace BasePage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetCarouselWidgetItem
 */
export interface BasePageItemWidgetCarouselWidgetItem {
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<CarouselWidgetItem>}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    items?: Array<CarouselWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetGridWidgetItem
 */
export interface BasePageItemWidgetGridWidgetItem {
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<GridWidgetItem>}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    items?: Array<GridWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetMapWidgetItem
 */
export interface BasePageItemWidgetMapWidgetItem {
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<MapWidgetItem>}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    items?: Array<MapWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetMapWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetSliderWidgetItem
 */
export interface BasePageItemWidgetSliderWidgetItem {
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<SliderWidgetItem>}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    items?: Array<SliderWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetTabbedContentWidgetItem
 */
export interface BasePageItemWidgetTabbedContentWidgetItem {
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<TabbedContentWidgetItem>}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    items?: Array<TabbedContentWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BaseSearchItem
 */
export interface BaseSearchItem {
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseSearchItem
     */
    score?: number;
}

/**
 * 
 * @export
 * @interface BaseWidget
 */
export interface BaseWidget {
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BaseWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BaseWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BaseWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BaseWidgetItem
 */
export interface BaseWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BaseWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BaseWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BreadcrumbItem
 */
export interface BreadcrumbItem {
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbItem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbItem
     */
    url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BreadcrumbItem
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbItem
     */
    forcedCulture?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BreadcrumbItem
     */
    showAsLink?: boolean;
}

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface Claim
 */
export interface Claim {
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    issuer?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    originalIssuer?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Claim
     */
    properties?: { [key: string]: string; };
    /**
     * 
     * @type {ClaimsIdentity}
     * @memberof Claim
     */
    subject?: ClaimsIdentity;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    valueType?: string;
}

/**
 * 
 * @export
 * @interface ClaimsIdentity
 */
export interface ClaimsIdentity {
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    authenticationType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIdentity
     */
    isAuthenticated?: boolean;
    /**
     * 
     * @type {ClaimsIdentity}
     * @memberof ClaimsIdentity
     */
    actor?: ClaimsIdentity;
    /**
     * 
     * @type {any}
     * @memberof ClaimsIdentity
     */
    bootstrapContext?: any;
    /**
     * 
     * @type {Array<Claim>}
     * @memberof ClaimsIdentity
     */
    claims?: Array<Claim>;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    nameClaimType?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    roleClaimType?: string;
}

/**
 * 
 * @export
 * @interface ContactVisitLog
 */
export interface ContactVisitLog {
    /**
     * 
     * @type {string}
     * @memberof ContactVisitLog
     */
    contactID?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactVisitLog
     */
    contactType: ContactVisitLog.ContactTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactVisitLog
     */
    userAgent: string;
    /**
     * 
     * @type {string}
     * @memberof ContactVisitLog
     */
    typeName?: string;
}

/**
 * @export
 * @namespace ContactVisitLog
 */
export namespace ContactVisitLog {
    /**
     * @export
     * @enum {string}
     */
    export enum ContactTypeEnum {
        Black = <any> 'Black',
        Statistics = <any> 'Statistics',
        Technical = <any> 'Technical'
    }
}

/**
 * 
 * @export
 * @interface ContactVisitResponse
 */
export interface ContactVisitResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ContactVisitResponse
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactVisitResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactVisitResponse
     */
    contactID?: string;
}

/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryName?: string;
}

/**
 * 
 * @export
 * @interface CryptoProviderCache
 */
export interface CryptoProviderCache {
}

/**
 * 
 * @export
 * @interface CryptoProviderFactory
 */
export interface CryptoProviderFactory {
    /**
     * 
     * @type {CryptoProviderCache}
     * @memberof CryptoProviderFactory
     */
    cryptoProviderCache?: CryptoProviderCache;
    /**
     * 
     * @type {ICryptoProvider}
     * @memberof CryptoProviderFactory
     */
    customCryptoProvider?: ICryptoProvider;
    /**
     * 
     * @type {boolean}
     * @memberof CryptoProviderFactory
     */
    cacheSignatureProviders?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CryptoProviderFactory
     */
    signatureProviderObjectPoolCacheSize?: number;
}

/**
 * 
 * @export
 * @interface Detail
 */
export interface Detail {
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    documentName?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    documentCulture?: string;
    /**
     * 
     * @type {Date}
     * @memberof Detail
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof Detail
     */
    permanentUrl?: string;
}

/**
 * 
 * @export
 * @interface EncryptingCredentials
 */
export interface EncryptingCredentials {
    /**
     * 
     * @type {string}
     * @memberof EncryptingCredentials
     */
    alg?: string;
    /**
     * 
     * @type {string}
     * @memberof EncryptingCredentials
     */
    enc?: string;
    /**
     * 
     * @type {CryptoProviderFactory}
     * @memberof EncryptingCredentials
     */
    cryptoProviderFactory?: CryptoProviderFactory;
    /**
     * 
     * @type {SecurityKey}
     * @memberof EncryptingCredentials
     */
    key?: SecurityKey;
}

/**
 * 
 * @export
 * @interface EventCategory
 */
export interface EventCategory {
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    eventCategoryGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    eventCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface ForeignRepresentation
 */
export interface ForeignRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ForeignRepresentation
     */
    foreignRepGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepresentation
     */
    foreignRepName?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepresentation
     */
    nodeAliasPathRoot?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ForeignRepresentation
     */
    newsFeedsList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepresentation
     */
    countryCodes?: string;
    /**
     * 
     * @type {Array<Country>}
     * @memberof ForeignRepresentation
     */
    countriesList?: Array<Country>;
    /**
     * 
     * @type {Array<Language>}
     * @memberof ForeignRepresentation
     */
    languagesList?: Array<Language>;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepresentation
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface ICryptoProvider
 */
export interface ICryptoProvider {
}

/**
 * 
 * @export
 * @interface ItineraryFolder
 */
export interface ItineraryFolder {
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    title?: string;
    /**
     * 
     * @type {Array<ItineraryItem>}
     * @memberof ItineraryFolder
     */
    itineraryItems?: Array<ItineraryItem>;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryFolder
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryFolder
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryFolder
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryFolder
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryFolder
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ItineraryFolder
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof ItineraryFolder
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryFolder
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryFolder
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface ItineraryItem
 */
export interface ItineraryItem {
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    perex?: string;
    /**
     * 
     * @type {number}
     * @memberof ItineraryItem
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ItineraryItem
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    publicTransportTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    carTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    carDistance?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryItem
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ItineraryItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof ItineraryItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface JwtSecurityToken
 */
export interface JwtSecurityToken {
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    actor?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtSecurityToken
     */
    audiences?: Array<string>;
    /**
     * 
     * @type {Array<Claim>}
     * @memberof JwtSecurityToken
     */
    claims?: Array<Claim>;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    encodedHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    encodedPayload?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof JwtSecurityToken
     */
    header?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    issuer?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof JwtSecurityToken
     */
    payload?: { [key: string]: any; };
    /**
     * 
     * @type {JwtSecurityToken}
     * @memberof JwtSecurityToken
     */
    innerToken?: JwtSecurityToken;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    rawAuthenticationTag?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    rawCiphertext?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    rawData?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    rawEncryptedKey?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    rawInitializationVector?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    rawHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    rawPayload?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    rawSignature?: string;
    /**
     * 
     * @type {SecurityKey}
     * @memberof JwtSecurityToken
     */
    securityKey?: SecurityKey;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    signatureAlgorithm?: string;
    /**
     * 
     * @type {SigningCredentials}
     * @memberof JwtSecurityToken
     */
    signingCredentials?: SigningCredentials;
    /**
     * 
     * @type {EncryptingCredentials}
     * @memberof JwtSecurityToken
     */
    encryptingCredentials?: EncryptingCredentials;
    /**
     * 
     * @type {SecurityKey}
     * @memberof JwtSecurityToken
     */
    signingKey?: SecurityKey;
    /**
     * 
     * @type {string}
     * @memberof JwtSecurityToken
     */
    subject?: string;
    /**
     * 
     * @type {Date}
     * @memberof JwtSecurityToken
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JwtSecurityToken
     */
    validTo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JwtSecurityToken
     */
    issuedAt?: Date;
}

/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    languageName?: string;
}

/**
 * 
 * @export
 * @interface LiftInfo
 */
export interface LiftInfo {
    /**
     * 
     * @type {string}
     * @memberof LiftInfo
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiftInfo
     */
    hideOnWebsite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiftInfo
     */
    liftID?: string;
    /**
     * 
     * @type {string}
     * @memberof LiftInfo
     */
    locationID?: string;
    /**
     * 
     * @type {string}
     * @memberof LiftInfo
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof LiftInfo
     */
    lastChange?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof LiftInfo
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiftInfo
     */
    isMain?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LiftInfo
     */
    typeCode?: number;
    /**
     * 
     * @type {string}
     * @memberof LiftInfo
     */
    typeText?: string;
    /**
     * 
     * @type {number}
     * @memberof LiftInfo
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof LiftInfo
     */
    capacity?: number;
    /**
     * 
     * @type {number}
     * @memberof LiftInfo
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof LiftInfo
     */
    statusText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiftInfo
     */
    nightSkiing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiftInfo
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiftInfo
     */
    caption?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiftInfo
     */
    isOpened?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiftInfo
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    rel?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    method?: string;
}

/**
 * 
 * @export
 * @interface MapPin
 */
export interface MapPin {
    /**
     * 
     * @type {string}
     * @memberof MapPin
     */
    pageTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof MapPin
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MapPin
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MapPin
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof MapPin
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof MapPin
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MapPin
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof MapPin
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MapPin
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof MapPin
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Array<PinCategory>}
     * @memberof MapPin
     */
    categories?: Array<PinCategory>;
    /**
     * 
     * @type {Array<PinSubCategory>}
     * @memberof MapPin
     */
    subCategories?: Array<PinSubCategory>;
    /**
     * 
     * @type {PinRegion}
     * @memberof MapPin
     */
    region?: PinRegion;
}

/**
 * 
 * @export
 * @interface NewsletterContact
 */
export interface NewsletterContact {
    /**
     * 
     * @type {string}
     * @memberof NewsletterContact
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterContact
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterContact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterContact
     */
    reCaptchaToken?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewsletterContact
     */
    newsletterNames: Array<string>;
}

/**
 * 
 * @export
 * @interface NewsletterInfo
 */
export interface NewsletterInfo {
    /**
     * 
     * @type {string}
     * @memberof NewsletterInfo
     */
    newsletterGUID?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterInfo
     */
    newsletterDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterInfo
     */
    newsletterName?: string;
}

/**
 * 
 * @export
 * @interface PageList
 */
export interface PageList {
    /**
     * 
     * @type {Array<any>}
     * @memberof PageList
     */
    items?: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof PageList
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof PageList
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageList
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PageList
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PageList
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PageList
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PageList
     */
    apiLinks?: Array<Link>;
}

/**
 * 
 * @export
 * @interface PageTag
 */
export interface PageTag {
    /**
     * 
     * @type {string}
     * @memberof PageTag
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof PageTag
     */
    pageTagGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PageTag
     */
    pageTagName?: string;
    /**
     * 
     * @type {string}
     * @memberof PageTag
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PageTag
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface PinCategory
 */
export interface PinCategory {
    /**
     * 
     * @type {string}
     * @memberof PinCategory
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof PinCategory
     */
    categoryGuid?: string;
}

/**
 * 
 * @export
 * @interface PinRegion
 */
export interface PinRegion {
    /**
     * 
     * @type {string}
     * @memberof PinRegion
     */
    regionName?: string;
    /**
     * 
     * @type {string}
     * @memberof PinRegion
     */
    regionGuid?: string;
}

/**
 * 
 * @export
 * @interface PinSubCategory
 */
export interface PinSubCategory {
    /**
     * 
     * @type {string}
     * @memberof PinSubCategory
     */
    subCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof PinSubCategory
     */
    subCategoryGuid?: string;
}

/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    regionGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    regionName?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface ResourceString
 */
export interface ResourceString {
    /**
     * 
     * @type {string}
     * @memberof ResourceString
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceString
     */
    resourceText?: string;
    /**
     * 
     * @type {any}
     * @memberof ResourceString
     */
    resourceTextJson?: any;
    /**
     * 
     * @type {string}
     * @memberof ResourceString
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface ResponseInfoMessage
 */
export interface ResponseInfoMessage {
    /**
     * 
     * @type {boolean}
     * @memberof ResponseInfoMessage
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseInfoMessage
     */
    message?: string;
}

/**
 * 
 * @export
 * @interface SearchResults
 */
export interface SearchResults {
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<BaseSearchItem>}
     * @memberof SearchResults
     */
    items?: Array<BaseSearchItem>;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchResults
     */
    searchedTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResults
     */
    searchQueryUsed?: string;
}

/**
 * 
 * @export
 * @interface SecurityKey
 */
export interface SecurityKey {
    /**
     * 
     * @type {number}
     * @memberof SecurityKey
     */
    keySize?: number;
    /**
     * 
     * @type {string}
     * @memberof SecurityKey
     */
    keyId?: string;
    /**
     * 
     * @type {CryptoProviderFactory}
     * @memberof SecurityKey
     */
    cryptoProviderFactory?: CryptoProviderFactory;
}

/**
 * 
 * @export
 * @interface SigningCredentials
 */
export interface SigningCredentials {
    /**
     * 
     * @type {string}
     * @memberof SigningCredentials
     */
    algorithm?: string;
    /**
     * 
     * @type {string}
     * @memberof SigningCredentials
     */
    digest?: string;
    /**
     * 
     * @type {CryptoProviderFactory}
     * @memberof SigningCredentials
     */
    cryptoProviderFactory?: CryptoProviderFactory;
    /**
     * 
     * @type {SecurityKey}
     * @memberof SigningCredentials
     */
    key?: SecurityKey;
    /**
     * 
     * @type {string}
     * @memberof SigningCredentials
     */
    kid?: string;
}

/**
 * 
 * @export
 * @interface SiteCulture
 */
export interface SiteCulture {
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureName?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureShortName?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureShortNameNative?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureLangCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureCountry?: string;
}

/**
 * 
 * @export
 * @interface SlopeInfo
 */
export interface SlopeInfo {
    /**
     * 
     * @type {string}
     * @memberof SlopeInfo
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SlopeInfo
     */
    hideOnWebsite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SlopeInfo
     */
    slopeID?: string;
    /**
     * 
     * @type {string}
     * @memberof SlopeInfo
     */
    locationID?: string;
    /**
     * 
     * @type {string}
     * @memberof SlopeInfo
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof SlopeInfo
     */
    lastChange?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SlopeInfo
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SlopeInfo
     */
    difficultyCode?: number;
    /**
     * 
     * @type {string}
     * @memberof SlopeInfo
     */
    difficultyText?: string;
    /**
     * 
     * @type {number}
     * @memberof SlopeInfo
     */
    exceed?: number;
    /**
     * 
     * @type {number}
     * @memberof SlopeInfo
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof SlopeInfo
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof SlopeInfo
     */
    statusText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SlopeInfo
     */
    snowMaking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SlopeInfo
     */
    nightSkiing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SlopeInfo
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SlopeInfo
     */
    caption?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SlopeInfo
     */
    isOpened?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SlopeInfo
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface SpaVoucherInfo
 */
export interface SpaVoucherInfo {
    /**
     * 
     * @type {string}
     * @memberof SpaVoucherInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SpaVoucherInfo
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof SpaVoucherInfo
     */
    vouchersListTitle?: string;
    /**
     * 
     * @type {Array<VoucherMonthInfo>}
     * @memberof SpaVoucherInfo
     */
    vouchersAvailability?: Array<VoucherMonthInfo>;
    /**
     * 
     * @type {string}
     * @memberof SpaVoucherInfo
     */
    buttonTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof SpaVoucherInfo
     */
    buttonUrl?: string;
}

/**
 * 
 * @export
 * @interface SubCategory
 */
export interface SubCategory {
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    subCategoryGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    subCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    cultureCode?: string;
    /**
     * 
     * @type {Category}
     * @memberof SubCategory
     */
    category?: Category;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    userGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    enabled?: boolean;
}

/**
 * 
 * @export
 * @interface ValidationResponse
 */
export interface ValidationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidationResponse
     */
    isValid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ValidationResponse
     */
    message?: string;
}

/**
 * 
 * @export
 * @interface VotingResponse
 */
export interface VotingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VotingResponse
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VotingResponse
     */
    message?: string;
}

/**
 * 
 * @export
 * @interface VoucherMonthInfo
 */
export interface VoucherMonthInfo {
    /**
     * 
     * @type {string}
     * @memberof VoucherMonthInfo
     */
    month?: string;
    /**
     * 
     * @type {number}
     * @memberof VoucherMonthInfo
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherMonthInfo
     */
    limit?: number;
}

/**
 * 
 * @export
 * @interface WebCamInfo
 */
export interface WebCamInfo {
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebCamInfo
     */
    hideOnWebsite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    webCamID?: string;
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    locationID?: string;
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof WebCamInfo
     */
    temperature?: number;
    /**
     * 
     * @type {Date}
     * @memberof WebCamInfo
     */
    lastChange?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof WebCamInfo
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    imageLink?: string;
    /**
     * 
     * @type {number}
     * @memberof WebCamInfo
     */
    imageWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof WebCamInfo
     */
    imageHeight?: number;
    /**
     * 
     * @type {Date}
     * @memberof WebCamInfo
     */
    imageLastChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    videoType?: string;
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    videoLink?: string;
    /**
     * 
     * @type {number}
     * @memberof WebCamInfo
     */
    videoWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof WebCamInfo
     */
    videoHeight?: number;
    /**
     * 
     * @type {Date}
     * @memberof WebCamInfo
     */
    videoLastChange?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof WebCamInfo
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    caption?: string;
    /**
     * 
     * @type {string}
     * @memberof WebCamInfo
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface WinterInfo
 */
export interface WinterInfo {
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WinterInfo
     */
    hideOnWebsite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    detailNodeGUID?: string;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    locationID?: string;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    locationName?: string;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    region?: string;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    temperature?: number;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    weatherCode?: number;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    weatherText?: string;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    snowCode?: number;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    snowText?: string;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    snowSlopesMin?: number;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    snowSlopesMax?: number;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    snowSlopesAvg?: number;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    snowOutsideSlopes?: number;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    newSnow?: number;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    operationStart?: string;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    operationEnd?: string;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    operationCode?: number;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    operationText?: string;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof WinterInfo
     */
    lastChange?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof WinterInfo
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<WebCamInfo>}
     * @memberof WinterInfo
     */
    webCams?: Array<WebCamInfo>;
    /**
     * 
     * @type {Array<SlopeInfo>}
     * @memberof WinterInfo
     */
    slopes?: Array<SlopeInfo>;
    /**
     * 
     * @type {Array<LiftInfo>}
     * @memberof WinterInfo
     */
    lifts?: Array<LiftInfo>;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    weatherCustomText?: string;
    /**
     * 
     * @type {number}
     * @memberof WinterInfo
     */
    mainTemperature?: number;
    /**
     * 
     * @type {string}
     * @memberof WinterInfo
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface ArticlePage
 */
export interface ArticlePage {
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    imageFilterType?: ArticlePage.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof ArticlePage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticlePage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof ArticlePage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ArticlePage
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof ArticlePage
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof ArticlePage
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ArticlePage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof ArticlePage
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    moreInfo?: string;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    date?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    hideDate?: boolean;
    /**
     * 
     * @type {Array<Category>}
     * @memberof ArticlePage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof ArticlePage
     */
    subCategories?: Array<SubCategory>;
    /**
     * 
     * @type {Region}
     * @memberof ArticlePage
     */
    region?: Region;
}

/**
 * @export
 * @namespace ArticlePage
 */
export namespace ArticlePage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface BaseWidgetItemWithPageSelection
 */
export interface BaseWidgetItemWithPageSelection {
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BaseWidgetItemWithPageSelection
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof BaseWidgetItemWithPageSelection
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidgetItemWithPageSelection
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    url?: string;
}

/**
 * 
 * @export
 * @interface CarouselWidget
 */
export interface CarouselWidget {
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<CarouselWidgetItem>}
     * @memberof CarouselWidget
     */
    items?: Array<CarouselWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof CarouselWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CarouselWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof CarouselWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface CategoryPage
 */
export interface CategoryPage {
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    imageFilterType?: CategoryPage.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPage
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof CategoryPage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof CategoryPage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof CategoryPage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof CategoryPage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof CategoryPage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CategoryPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof CategoryPage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CategoryPage
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof CategoryPage
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof CategoryPage
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof CategoryPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CategoryPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CategoryPage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CategoryPage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CategoryPage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof CategoryPage
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    cultureCode?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof CategoryPage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof CategoryPage
     */
    subCategories?: Array<SubCategory>;
}

/**
 * @export
 * @namespace CategoryPage
 */
export namespace CategoryPage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface CertifiedSubjectsWidget
 */
export interface CertifiedSubjectsWidget {
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof CertifiedSubjectsWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof CertifiedSubjectsWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CertifiedSubjectsWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CertifiedSubjectsWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CertifiedSubjectsWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CertifiedSubjectsWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof CertifiedSubjectsWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface EventPage
 */
export interface EventPage {
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    imageFilterType?: EventPage.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EventPage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof EventPage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof EventPage
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof EventPage
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof EventPage
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof EventPage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof EventPage
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    cultureCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    startDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    endDateTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof EventPage
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EventPage
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    organizer?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    webUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    web2Url?: string;
    /**
     * 
     * @type {Region}
     * @memberof EventPage
     */
    region?: Region;
    /**
     * 
     * @type {EventCategory}
     * @memberof EventPage
     */
    eventCategory?: EventCategory;
}

/**
 * @export
 * @namespace EventPage
 */
export namespace EventPage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface FaqWidget
 */
export interface FaqWidget {
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof FaqWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof FaqWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof FaqWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    showInRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    backgroundImage?: string;
    /**
     * 
     * @type {number}
     * @memberof FaqWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<BaseWidgetItem>}
     * @memberof FaqWidget
     */
    items?: Array<BaseWidgetItem>;
}

/**
 * 
 * @export
 * @interface FaqWidgetItem
 */
export interface FaqWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof FaqWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof FaqWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    content?: string;
}

/**
 * 
 * @export
 * @interface ForeignRepsMapWidget
 */
export interface ForeignRepsMapWidget {
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignRepsMapWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignRepsMapWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignRepsMapWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof ForeignRepsMapWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignRepsMapWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ForeignRepsMapWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ForeignRepsMapWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ForeignRepsMapWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ForeignRepsMapWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignRepsMapWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ForeignRepsMapWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof ForeignRepsMapWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignRepsMapWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidget
     */
    continent?: string;
    /**
     * 
     * @type {Array<ForeignRepsMapWidgetItem>}
     * @memberof ForeignRepsMapWidget
     */
    items?: Array<ForeignRepsMapWidgetItem>;
}

/**
 * 
 * @export
 * @interface ForeignRepsMapWidgetItem
 */
export interface ForeignRepsMapWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ForeignRepsMapWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ForeignRepsMapWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ForeignRepsMapWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ForeignRepsMapWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignRepsMapWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ForeignRepsMapWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof ForeignRepsMapWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ForeignRepsMapWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    countryCodes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ForeignRepsMapWidgetItem
     */
    countryCodesList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ForeignRepsMapWidgetItem
     */
    url?: string;
}

/**
 * 
 * @export
 * @interface GalleryWidget
 */
export interface GalleryWidget {
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof GalleryWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof GalleryWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof GalleryWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {number}
     * @memberof GalleryWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<BaseWidgetItem>}
     * @memberof GalleryWidget
     */
    items?: Array<BaseWidgetItem>;
}

/**
 * 
 * @export
 * @interface GalleryWidgetItem
 */
export interface GalleryWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof GalleryWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof GalleryWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    imageFilterType?: GalleryWidgetItem.ImageFilterTypeEnum;
}

/**
 * @export
 * @namespace GalleryWidgetItem
 */
export namespace GalleryWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface GridWidget
 */
export interface GridWidget {
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<GridWidgetItem>}
     * @memberof GridWidget
     */
    items?: Array<GridWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof GridWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof GridWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof GridWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof GridWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    backgroundImage?: string;
    /**
     * 
     * @type {Array<PageTag>}
     * @memberof GridWidget
     */
    taxonomyTags?: Array<PageTag>;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    widgetStyle?: GridWidget.WidgetStyleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    showFlags?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    itemImageAspectRatio?: string;
}

/**
 * @export
 * @namespace GridWidget
 */
export namespace GridWidget {
    /**
     * @export
     * @enum {string}
     */
    export enum WidgetStyleEnum {
        Classic = <any> 'classic',
        Classicsubt = <any> 'classicsubt',
        Compact = <any> 'compact',
        Extended = <any> 'extended',
        Brochures = <any> 'brochures',
        Simplelist = <any> 'simplelist',
        Iconbox = <any> 'iconbox'
    }
}

/**
 * 
 * @export
 * @interface HtmlContentWidget
 */
export interface HtmlContentWidget {
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof HtmlContentWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof HtmlContentWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HtmlContentWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HtmlContentWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HtmlContentWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof HtmlContentWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof HtmlContentWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    showInRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    imagePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    imageCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    imageLinkUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    themeStyle?: HtmlContentWidget.ThemeStyleEnum;
}

/**
 * @export
 * @namespace HtmlContentWidget
 */
export namespace HtmlContentWidget {
    /**
     * @export
     * @enum {string}
     */
    export enum ThemeStyleEnum {
        None = <any> 'none',
        Dark = <any> 'dark'
    }
}

/**
 * 
 * @export
 * @interface IframeWidget
 */
export interface IframeWidget {
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof IframeWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof IframeWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IframeWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IframeWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IframeWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof IframeWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof IframeWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    showInRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof IframeWidget
     */
    height?: number;
}

/**
 * 
 * @export
 * @interface ItineraryPage
 */
export interface ItineraryPage {
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    imageFilterType?: ItineraryPage.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPage
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryPage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryPage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryPage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryPage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof ItineraryPage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItineraryPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof ItineraryPage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ItineraryPage
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof ItineraryPage
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof ItineraryPage
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryPage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryPage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ItineraryPage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof ItineraryPage
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    cultureCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ItineraryPage
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    publicTransportTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    carTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPage
     */
    carDistance?: string;
    /**
     * 
     * @type {Array<PageTag>}
     * @memberof ItineraryPage
     */
    pageTags?: Array<PageTag>;
    /**
     * 
     * @type {Array<ItineraryFolder>}
     * @memberof ItineraryPage
     */
    itineraryFolders?: Array<ItineraryFolder>;
}

/**
 * @export
 * @namespace ItineraryPage
 */
export namespace ItineraryPage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface MapWidget
 */
export interface MapWidget {
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<MapWidgetItem>}
     * @memberof MapWidget
     */
    items?: Array<MapWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof MapWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof MapWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MapWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MapWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MapWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof MapWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof MapWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    loadItemsFromWidget?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    widgetPathGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    mapCenterFromParent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    mapCenterLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    mapCenterLongitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWidget
     */
    mapZoom?: number;
    /**
     * 
     * @type {string}
     * @memberof MapWidget
     */
    mapType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidget
     */
    showInRightColumn?: boolean;
}

/**
 * 
 * @export
 * @interface NavigationFolder
 */
export interface NavigationFolder {
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    cssClass?: string;
    /**
     * 
     * @type {number}
     * @memberof NavigationFolder
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof NavigationFolder
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof NavigationFolder
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationFolder
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationFolder
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationFolder
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationFolder
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    permanentPath?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof NavigationFolder
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationFolder
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationFolder
     */
    isHorizontalMenu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationFolder
     */
    isImageMenu?: boolean;
    /**
     * 
     * @type {Array<BaseNavigationItem>}
     * @memberof NavigationFolder
     */
    items: Array<BaseNavigationItem>;
}

/**
 * 
 * @export
 * @interface NavigationItem
 */
export interface NavigationItem {
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    cssClass?: string;
    /**
     * 
     * @type {number}
     * @memberof NavigationItem
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof NavigationItem
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof NavigationItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof NavigationItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    target?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    url: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NavigationItem
     */
    alternativeUrls: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    forcedCulture?: string;
}

/**
 * 
 * @export
 * @interface NewsletterFormWidget
 */
export interface NewsletterFormWidget {
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof NewsletterFormWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterFormWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterFormWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterFormWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterFormWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof NewsletterFormWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof NewsletterFormWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    newsletterType?: string;
}

/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    imageFilterType?: Page.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof Page
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof Page
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof Page
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof Page
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Page
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Page
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof Page
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof Page
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof Page
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof Page
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof Page
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Page
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Page
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Page
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof Page
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof Page
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    cultureCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    priority?: number;
    /**
     * 
     * @type {Array<PageTag>}
     * @memberof Page
     */
    pageTags?: Array<PageTag>;
}

/**
 * @export
 * @namespace Page
 */
export namespace Page {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface PanoramicPhotoWidget
 */
export interface PanoramicPhotoWidget {
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PanoramicPhotoWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof PanoramicPhotoWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PanoramicPhotoWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PanoramicPhotoWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PanoramicPhotoWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof PanoramicPhotoWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof PanoramicPhotoWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    imageCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    imageFilterType?: PanoramicPhotoWidget.ImageFilterTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    automaticRotation?: boolean;
}

/**
 * @export
 * @namespace PanoramicPhotoWidget
 */
export namespace PanoramicPhotoWidget {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface PlacePage
 */
export interface PlacePage {
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    imageFilterType?: PlacePage.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePage
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PlacePage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PlacePage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PlacePage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PlacePage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PlacePage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlacePage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof PlacePage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof PlacePage
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof PlacePage
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof PlacePage
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof PlacePage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlacePage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlacePage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlacePage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof PlacePage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof PlacePage
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePage
     */
    showWeatherInfo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePage
     */
    isEDEN?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlacePage
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PlacePage
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    webUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    web2Url?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePage
     */
    twitterUrl?: string;
    /**
     * 
     * @type {SpaVoucherInfo}
     * @memberof PlacePage
     */
    spaVouchers?: SpaVoucherInfo;
    /**
     * 
     * @type {WinterInfo}
     * @memberof PlacePage
     */
    holidayInfo?: WinterInfo;
    /**
     * 
     * @type {Array<Category>}
     * @memberof PlacePage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof PlacePage
     */
    subCategories?: Array<SubCategory>;
    /**
     * 
     * @type {Region}
     * @memberof PlacePage
     */
    region?: Region;
}

/**
 * @export
 * @namespace PlacePage
 */
export namespace PlacePage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface RegionPage
 */
export interface RegionPage {
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    imageFilterType?: RegionPage.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPage
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof RegionPage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof RegionPage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof RegionPage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof RegionPage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof RegionPage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegionPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof RegionPage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof RegionPage
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof RegionPage
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof RegionPage
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof RegionPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RegionPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RegionPage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RegionPage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof RegionPage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof RegionPage
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPage
     */
    regionMapImage?: string;
    /**
     * 
     * @type {Region}
     * @memberof RegionPage
     */
    region?: Region;
}

/**
 * @export
 * @namespace RegionPage
 */
export namespace RegionPage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface SafeTravelsWidget
 */
export interface SafeTravelsWidget {
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof SafeTravelsWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SafeTravelsWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof SafeTravelsWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface SearchItemItineraryPage
 */
export interface SearchItemItineraryPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemItineraryPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemItineraryPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemItineraryPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemItineraryPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemItineraryPage
     */
    nodeAliasPath?: string;
}

/**
 * 
 * @export
 * @interface SearchItemPage
 */
export interface SearchItemPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    nodeAliasPath?: string;
}

/**
 * 
 * @export
 * @interface SliderWidget
 */
export interface SliderWidget {
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<SliderWidgetItem>}
     * @memberof SliderWidget
     */
    items?: Array<SliderWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof SliderWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SliderWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof SliderWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    sliderDelaySec?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    showShareButtons?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    showInPageNav?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    useContentWidth?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    aspectRatio?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    loadFirstItemFromParentPage?: boolean;
}

/**
 * 
 * @export
 * @interface SmartGuideWidget
 */
export interface SmartGuideWidget {
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmartGuideWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SmartGuideWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SmartGuideWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof SmartGuideWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof SmartGuideWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SmartGuideWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SmartGuideWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SmartGuideWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SmartGuideWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SmartGuideWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SmartGuideWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof SmartGuideWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmartGuideWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    audioUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartGuideWidget
     */
    deepLinkUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmartGuideWidget
     */
    showInRightColumn?: boolean;
}

/**
 * 
 * @export
 * @interface SocialChannelWidget
 */
export interface SocialChannelWidget {
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof SocialChannelWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SocialChannelWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocialChannelWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocialChannelWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocialChannelWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SocialChannelWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof SocialChannelWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface TabbedContentWidget
 */
export interface TabbedContentWidget {
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    isHiddenForPage?: boolean;
    /**
     * 
     * @type {Array<TabbedContentWidgetItem>}
     * @memberof TabbedContentWidget
     */
    items?: Array<TabbedContentWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof TabbedContentWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof TabbedContentWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof TabbedContentWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    showInRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    backgroundImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    imagePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    imageCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    imageLinkUrl?: string;
}

/**
 * 
 * @export
 * @interface TradeOfferPage
 */
export interface TradeOfferPage {
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    imageFilterType?: TradeOfferPage.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOfferPage
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOfferPage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOfferPage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof TradeOfferPage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof TradeOfferPage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof TradeOfferPage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof TradeOfferPage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof TradeOfferPage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TradeOfferPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof TradeOfferPage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof TradeOfferPage
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof TradeOfferPage
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof TradeOfferPage
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof TradeOfferPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeOfferPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeOfferPage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeOfferPage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOfferPage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof TradeOfferPage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof TradeOfferPage
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOfferPage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOfferPage
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {Array<ForeignRepresentation>}
     * @memberof TradeOfferPage
     */
    foreignRepresentations?: Array<ForeignRepresentation>;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    webUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    web2Url?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferPage
     */
    twitterUrl?: string;
}

/**
 * @export
 * @namespace TradeOfferPage
 */
export namespace TradeOfferPage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface TradeOffersWidget
 */
export interface TradeOffersWidget {
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOffersWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOffersWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOffersWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof TradeOffersWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOffersWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof TradeOffersWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeOffersWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeOffersWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeOffersWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOffersWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof TradeOffersWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof TradeOffersWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeOffersWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {Array<ForeignRepresentation>}
     * @memberof TradeOffersWidget
     */
    filteringOptions?: Array<ForeignRepresentation>;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    filterByForeignRepGuids?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffersWidget
     */
    filterByLanguageCodes?: string;
    /**
     * 
     * @type {Array<TradeOfferPage>}
     * @memberof TradeOffersWidget
     */
    items?: Array<TradeOfferPage>;
    /**
     * 
     * @type {number}
     * @memberof TradeOffersWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffersWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffersWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffersWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffersWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffersWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffersWidget
     */
    itemsCountTotal?: number;
}

/**
 * 
 * @export
 * @interface VenueFinderWidget
 */
export interface VenueFinderWidget {
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof VenueFinderWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    enableCultureOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof VenueFinderWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VenueFinderWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VenueFinderWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VenueFinderWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof VenueFinderWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof VenueFinderWidget
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface ArticlePageSimplified
 */
export interface ArticlePageSimplified {
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    imageFilterType?: ArticlePageSimplified.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePageSimplified
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePageSimplified
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePageSimplified
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePageSimplified
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePageSimplified
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePageSimplified
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePageSimplified
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof ArticlePageSimplified
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticlePageSimplified
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof ArticlePageSimplified
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ArticlePageSimplified
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof ArticlePageSimplified
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof ArticlePageSimplified
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePageSimplified
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePageSimplified
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePageSimplified
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePageSimplified
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePageSimplified
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ArticlePageSimplified
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof ArticlePageSimplified
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePageSimplified
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePageSimplified
     */
    moreInfo?: string;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePageSimplified
     */
    date?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePageSimplified
     */
    hideDate?: boolean;
    /**
     * 
     * @type {Array<Category>}
     * @memberof ArticlePageSimplified
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof ArticlePageSimplified
     */
    subCategories?: Array<SubCategory>;
    /**
     * 
     * @type {Region}
     * @memberof ArticlePageSimplified
     */
    region?: Region;
}

/**
 * @export
 * @namespace ArticlePageSimplified
 */
export namespace ArticlePageSimplified {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface CarouselWidgetItem
 */
export interface CarouselWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CarouselWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof CarouselWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    imageFilterType?: CarouselWidgetItem.ImageFilterTypeEnum;
    /**
     * 
     * @type {Region}
     * @memberof CarouselWidgetItem
     */
    region?: Region;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    dateTo?: Date;
}

/**
 * @export
 * @namespace CarouselWidgetItem
 */
export namespace CarouselWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface CategoryPageSimplified
 */
export interface CategoryPageSimplified {
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    imageFilterType?: CategoryPageSimplified.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPageSimplified
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPageSimplified
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPageSimplified
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof CategoryPageSimplified
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof CategoryPageSimplified
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof CategoryPageSimplified
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof CategoryPageSimplified
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof CategoryPageSimplified
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CategoryPageSimplified
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof CategoryPageSimplified
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CategoryPageSimplified
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof CategoryPageSimplified
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof CategoryPageSimplified
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof CategoryPageSimplified
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CategoryPageSimplified
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CategoryPageSimplified
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CategoryPageSimplified
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPageSimplified
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CategoryPageSimplified
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof CategoryPageSimplified
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryPageSimplified
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPageSimplified
     */
    cultureCode?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof CategoryPageSimplified
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof CategoryPageSimplified
     */
    subCategories?: Array<SubCategory>;
}

/**
 * @export
 * @namespace CategoryPageSimplified
 */
export namespace CategoryPageSimplified {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface EventPageSimplified
 */
export interface EventPageSimplified {
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    imageFilterType?: EventPageSimplified.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPageSimplified
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPageSimplified
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPageSimplified
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPageSimplified
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPageSimplified
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPageSimplified
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPageSimplified
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EventPageSimplified
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventPageSimplified
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof EventPageSimplified
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof EventPageSimplified
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof EventPageSimplified
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof EventPageSimplified
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof EventPageSimplified
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPageSimplified
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPageSimplified
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPageSimplified
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EventPageSimplified
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof EventPageSimplified
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof EventPageSimplified
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPageSimplified
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    cultureCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventPageSimplified
     */
    startDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPageSimplified
     */
    endDateTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof EventPageSimplified
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EventPageSimplified
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    organizer?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    webUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPageSimplified
     */
    web2Url?: string;
    /**
     * 
     * @type {Region}
     * @memberof EventPageSimplified
     */
    region?: Region;
    /**
     * 
     * @type {EventCategory}
     * @memberof EventPageSimplified
     */
    eventCategory?: EventCategory;
}

/**
 * @export
 * @namespace EventPageSimplified
 */
export namespace EventPageSimplified {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface GridWidgetItem
 */
export interface GridWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof GridWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof GridWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    imageFilterType?: GridWidgetItem.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    urlText?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    widgetStyle?: GridWidgetItem.WidgetStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    shortDesc?: string;
    /**
     * 
     * @type {Region}
     * @memberof GridWidgetItem
     */
    region?: Region;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    dateTo?: Date;
}

/**
 * @export
 * @namespace GridWidgetItem
 */
export namespace GridWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WidgetStyleEnum {
        Classic = <any> 'classic',
        Classicsubt = <any> 'classicsubt',
        Compact = <any> 'compact',
        Extended = <any> 'extended',
        Brochures = <any> 'brochures',
        Simplelist = <any> 'simplelist',
        Iconbox = <any> 'iconbox'
    }
}

/**
 * 
 * @export
 * @interface ItineraryPageSimplified
 */
export interface ItineraryPageSimplified {
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    imageFilterType?: ItineraryPageSimplified.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPageSimplified
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPageSimplified
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPageSimplified
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryPageSimplified
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryPageSimplified
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryPageSimplified
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ItineraryPageSimplified
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof ItineraryPageSimplified
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItineraryPageSimplified
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof ItineraryPageSimplified
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ItineraryPageSimplified
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof ItineraryPageSimplified
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof ItineraryPageSimplified
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryPageSimplified
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryPageSimplified
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryPageSimplified
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItineraryPageSimplified
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPageSimplified
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ItineraryPageSimplified
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof ItineraryPageSimplified
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItineraryPageSimplified
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    cultureCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ItineraryPageSimplified
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    publicTransportTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    carTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryPageSimplified
     */
    carDistance?: string;
    /**
     * 
     * @type {Array<PageTag>}
     * @memberof ItineraryPageSimplified
     */
    pageTags?: Array<PageTag>;
    /**
     * 
     * @type {Array<ItineraryFolder>}
     * @memberof ItineraryPageSimplified
     */
    itineraryFolders?: Array<ItineraryFolder>;
}

/**
 * @export
 * @namespace ItineraryPageSimplified
 */
export namespace ItineraryPageSimplified {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface MapWidgetItem
 */
export interface MapWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof MapWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MapWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MapWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MapWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof MapWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof MapWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MapWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof MapWidgetItem
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWidgetItem
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    imageFilterType?: MapWidgetItem.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    urlText?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWidgetItem
     */
    perex?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof MapWidgetItem
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Date}
     * @memberof MapWidgetItem
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MapWidgetItem
     */
    dateTo?: Date;
}

/**
 * @export
 * @namespace MapWidgetItem
 */
export namespace MapWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface PageSimplified
 */
export interface PageSimplified {
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    imageFilterType?: PageSimplified.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PageSimplified
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PageSimplified
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PageSimplified
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PageSimplified
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PageSimplified
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PageSimplified
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PageSimplified
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PageSimplified
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PageSimplified
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof PageSimplified
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof PageSimplified
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof PageSimplified
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof PageSimplified
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof PageSimplified
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PageSimplified
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PageSimplified
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PageSimplified
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PageSimplified
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof PageSimplified
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof PageSimplified
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PageSimplified
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSimplified
     */
    cultureCode?: string;
    /**
     * 
     * @type {number}
     * @memberof PageSimplified
     */
    priority?: number;
    /**
     * 
     * @type {Array<PageTag>}
     * @memberof PageSimplified
     */
    pageTags?: Array<PageTag>;
}

/**
 * @export
 * @namespace PageSimplified
 */
export namespace PageSimplified {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface PlacePageSimplified
 */
export interface PlacePageSimplified {
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    imageFilterType?: PlacePageSimplified.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePageSimplified
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePageSimplified
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePageSimplified
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PlacePageSimplified
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PlacePageSimplified
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PlacePageSimplified
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof PlacePageSimplified
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PlacePageSimplified
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlacePageSimplified
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof PlacePageSimplified
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof PlacePageSimplified
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof PlacePageSimplified
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof PlacePageSimplified
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof PlacePageSimplified
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlacePageSimplified
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlacePageSimplified
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlacePageSimplified
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePageSimplified
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof PlacePageSimplified
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof PlacePageSimplified
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePageSimplified
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePageSimplified
     */
    showWeatherInfo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlacePageSimplified
     */
    isEDEN?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlacePageSimplified
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PlacePageSimplified
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    webUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    web2Url?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PlacePageSimplified
     */
    twitterUrl?: string;
    /**
     * 
     * @type {SpaVoucherInfo}
     * @memberof PlacePageSimplified
     */
    spaVouchers?: SpaVoucherInfo;
    /**
     * 
     * @type {WinterInfo}
     * @memberof PlacePageSimplified
     */
    holidayInfo?: WinterInfo;
    /**
     * 
     * @type {Array<Category>}
     * @memberof PlacePageSimplified
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof PlacePageSimplified
     */
    subCategories?: Array<SubCategory>;
    /**
     * 
     * @type {Region}
     * @memberof PlacePageSimplified
     */
    region?: Region;
}

/**
 * @export
 * @namespace PlacePageSimplified
 */
export namespace PlacePageSimplified {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface RegionPageSimplified
 */
export interface RegionPageSimplified {
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    titlePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    imageFilterType?: RegionPageSimplified.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    topLayerImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPageSimplified
     */
    useFirstWidgetAsHeroContent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPageSimplified
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    anchor?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    anchorTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPageSimplified
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof RegionPageSimplified
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof RegionPageSimplified
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof RegionPageSimplified
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof RegionPageSimplified
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof RegionPageSimplified
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    primaryUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegionPageSimplified
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof RegionPageSimplified
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof RegionPageSimplified
     */
    siteCulturesAllowed?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {NavigationFolder}
     * @memberof RegionPageSimplified
     */
    nearestNavigation?: NavigationFolder;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof RegionPageSimplified
     */
    pageBanners?: Array<Banner>;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof RegionPageSimplified
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RegionPageSimplified
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RegionPageSimplified
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RegionPageSimplified
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPageSimplified
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof RegionPageSimplified
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof RegionPageSimplified
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionPageSimplified
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionPageSimplified
     */
    regionMapImage?: string;
    /**
     * 
     * @type {Region}
     * @memberof RegionPageSimplified
     */
    region?: Region;
}

/**
 * @export
 * @namespace RegionPageSimplified
 */
export namespace RegionPageSimplified {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface SearchItemArticlePage
 */
export interface SearchItemArticlePage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemArticlePage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemArticlePage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemArticlePage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemArticlePage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemArticlePage
     */
    date?: Date;
    /**
     * 
     * @type {Array<Category>}
     * @memberof SearchItemArticlePage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof SearchItemArticlePage
     */
    subCategories?: Array<SubCategory>;
    /**
     * 
     * @type {Region}
     * @memberof SearchItemArticlePage
     */
    region?: Region;
}

/**
 * 
 * @export
 * @interface SearchItemCategoryPage
 */
export interface SearchItemCategoryPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemCategoryPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemCategoryPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemCategoryPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemCategoryPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemCategoryPage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof SearchItemCategoryPage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof SearchItemCategoryPage
     */
    subCategories?: Array<SubCategory>;
}

/**
 * 
 * @export
 * @interface SearchItemEventPage
 */
export interface SearchItemEventPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemEventPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemEventPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventPage
     */
    startDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventPage
     */
    endDateTime?: Date;
    /**
     * 
     * @type {Region}
     * @memberof SearchItemEventPage
     */
    region?: Region;
}

/**
 * 
 * @export
 * @interface SearchItemPlacePage
 */
export interface SearchItemPlacePage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemPlacePage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemPlacePage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemPlacePage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemPlacePage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPlacePage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof SearchItemPlacePage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof SearchItemPlacePage
     */
    subCategories?: Array<SubCategory>;
    /**
     * 
     * @type {Region}
     * @memberof SearchItemPlacePage
     */
    region?: Region;
}

/**
 * 
 * @export
 * @interface SearchItemRegionPage
 */
export interface SearchItemRegionPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemRegionPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemRegionPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemRegionPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemRegionPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemRegionPage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {Region}
     * @memberof SearchItemRegionPage
     */
    region?: Region;
}

/**
 * 
 * @export
 * @interface SearchItemTradeOfferPage
 */
export interface SearchItemTradeOfferPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemTradeOfferPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemTradeOfferPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemTradeOfferPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemTradeOfferPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemTradeOfferPage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchItemTradeOfferPage
     */
    isPrivate?: boolean;
}

/**
 * 
 * @export
 * @interface SliderWidgetItem
 */
export interface SliderWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SliderWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof SliderWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    imageFilterType?: SliderWidgetItem.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    video?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidgetItem
     */
    showVideoControls?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    topLayerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    sideContent?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    urlText?: string;
    /**
     * 
     * @type {Region}
     * @memberof SliderWidgetItem
     */
    region?: Region;
}

/**
 * @export
 * @namespace SliderWidgetItem
 */
export namespace SliderWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface TabbedContentWidgetItem
 */
export interface TabbedContentWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof TabbedContentWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {UserInfo}
     * @memberof TabbedContentWidgetItem
     */
    authenticatedUser?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    sideContent?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    urlText?: string;
}


/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(data: AuthRequest, options: any = {}): FetchArgs {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling authLogin.');
            }
            const localVarPath = `/vcr/api/auth/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(data || {}) : (data || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authReadToken(token: string, options: any = {}): FetchArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling authReadToken.');
            }
            const localVarPath = `/vcr/api/auth/read`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authValidate(token: string, options: any = {}): FetchArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling authValidate.');
            }
            const localVarPath = `/vcr/api/auth/validate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(data: AuthRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authLogin(data, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authReadToken(token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JwtSecurityToken> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authReadToken(token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authValidate(token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ValidationResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authValidate(token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AuthRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(data: AuthRequest, options?: any) {
            return AuthApiFp(configuration).authLogin(data, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authReadToken(token: string, options?: any) {
            return AuthApiFp(configuration).authReadToken(token, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authValidate(token: string, options?: any) {
            return AuthApiFp(configuration).authValidate(token, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogin(data: AuthRequest, options?: any) {
        return AuthApiFp(this.configuration).authLogin(data, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authReadToken(token: string, options?: any) {
        return AuthApiFp(this.configuration).authReadToken(token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authValidate(token: string, options?: any) {
        return AuthApiFp(this.configuration).authValidate(token, options)(this.fetch, this.basePath);
    }

}

/**
 * BannerStatitsticsApi - fetch parameter creator
 * @export
 */
export const BannerStatitsticsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BannerStatistics} record 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerStatitsticsAutomatedTranslationVoting(record: BannerStatistics, xAPIKey: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'record' is not null or undefined
            if (record === null || record === undefined) {
                throw new RequiredError('record','Required parameter record was null or undefined when calling bannerStatitsticsAutomatedTranslationVoting.');
            }
            // verify required parameter 'xAPIKey' is not null or undefined
            if (xAPIKey === null || xAPIKey === undefined) {
                throw new RequiredError('xAPIKey','Required parameter xAPIKey was null or undefined when calling bannerStatitsticsAutomatedTranslationVoting.');
            }
            const localVarPath = `/vcr/api/bannerstatistics/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xAPIKey !== undefined && xAPIKey !== null) {
                localVarHeaderParameter['X-API-Key'] = String(xAPIKey);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BannerStatistics" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(record || {}) : (record || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerStatitsticsApi - functional programming interface
 * @export
 */
export const BannerStatitsticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BannerStatistics} record 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerStatitsticsAutomatedTranslationVoting(record: BannerStatistics, xAPIKey: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseInfoMessage> {
            const localVarFetchArgs = BannerStatitsticsApiFetchParamCreator(configuration).bannerStatitsticsAutomatedTranslationVoting(record, xAPIKey, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BannerStatitsticsApi - factory interface
 * @export
 */
export const BannerStatitsticsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {BannerStatistics} record 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerStatitsticsAutomatedTranslationVoting(record: BannerStatistics, xAPIKey: string, authorization?: string, options?: any) {
            return BannerStatitsticsApiFp(configuration).bannerStatitsticsAutomatedTranslationVoting(record, xAPIKey, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * BannerStatitsticsApi - object-oriented interface
 * @export
 * @class BannerStatitsticsApi
 * @extends {BaseAPI}
 */
export class BannerStatitsticsApi extends BaseAPI {
    /**
     * 
     * @param {BannerStatistics} record 
     * @param {string} xAPIKey ApiKey header
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerStatitsticsApi
     */
    public bannerStatitsticsAutomatedTranslationVoting(record: BannerStatistics, xAPIKey: string, authorization?: string, options?: any) {
        return BannerStatitsticsApiFp(this.configuration).bannerStatitsticsAutomatedTranslationVoting(record, xAPIKey, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * CategoriesApi - fetch parameter creator
 * @export
 */
export const CategoriesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategories(cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategory(categoryGuid: string, cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'categoryGuid' is not null or undefined
            if (categoryGuid === null || categoryGuid === undefined) {
                throw new RequiredError('categoryGuid','Required parameter categoryGuid was null or undefined when calling categoriesGetCategory.');
            }
            const localVarPath = `/vcr/api/category/{categoryGuid}`
                .replace(`{${"categoryGuid"}}`, encodeURIComponent(String(categoryGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategories(cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Category>> {
            const localVarFetchArgs = CategoriesApiFetchParamCreator(configuration).categoriesGetCategories(cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategory(categoryGuid: string, cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Category> {
            const localVarFetchArgs = CategoriesApiFetchParamCreator(configuration).categoriesGetCategory(categoryGuid, cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategories(cultureCode?: string, authorization?: string, options?: any) {
            return CategoriesApiFp(configuration).categoriesGetCategories(cultureCode, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategory(categoryGuid: string, cultureCode?: string, authorization?: string, options?: any) {
            return CategoriesApiFp(configuration).categoriesGetCategory(categoryGuid, cultureCode, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesGetCategories(cultureCode?: string, authorization?: string, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesGetCategories(cultureCode, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} categoryGuid 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesGetCategory(categoryGuid: string, cultureCode?: string, authorization?: string, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesGetCategory(categoryGuid, cultureCode, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * ContactVisitApi - fetch parameter creator
 * @export
 */
export const ContactVisitApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactVisitLog} contact 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactVisitSubscribe(contact: ContactVisitLog, xAPIKey: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'contact' is not null or undefined
            if (contact === null || contact === undefined) {
                throw new RequiredError('contact','Required parameter contact was null or undefined when calling contactVisitSubscribe.');
            }
            // verify required parameter 'xAPIKey' is not null or undefined
            if (xAPIKey === null || xAPIKey === undefined) {
                throw new RequiredError('xAPIKey','Required parameter xAPIKey was null or undefined when calling contactVisitSubscribe.');
            }
            const localVarPath = `/vcr/api/contact/visit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xAPIKey !== undefined && xAPIKey !== null) {
                localVarHeaderParameter['X-API-Key'] = String(xAPIKey);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactVisitLog" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(contact || {}) : (contact || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactVisitApi - functional programming interface
 * @export
 */
export const ContactVisitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactVisitLog} contact 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactVisitSubscribe(contact: ContactVisitLog, xAPIKey: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactVisitResponse> {
            const localVarFetchArgs = ContactVisitApiFetchParamCreator(configuration).contactVisitSubscribe(contact, xAPIKey, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContactVisitApi - factory interface
 * @export
 */
export const ContactVisitApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ContactVisitLog} contact 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactVisitSubscribe(contact: ContactVisitLog, xAPIKey: string, authorization?: string, options?: any) {
            return ContactVisitApiFp(configuration).contactVisitSubscribe(contact, xAPIKey, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * ContactVisitApi - object-oriented interface
 * @export
 * @class ContactVisitApi
 * @extends {BaseAPI}
 */
export class ContactVisitApi extends BaseAPI {
    /**
     * 
     * @param {ContactVisitLog} contact 
     * @param {string} xAPIKey ApiKey header
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactVisitApi
     */
    public contactVisitSubscribe(contact: ContactVisitLog, xAPIKey: string, authorization?: string, options?: any) {
        return ContactVisitApiFp(this.configuration).contactVisitSubscribe(contact, xAPIKey, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * EventCategoriesApi - fetch parameter creator
 * @export
 */
export const EventCategoriesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategories(cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/eventcategories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategory(eventCategoryGuid: string, cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'eventCategoryGuid' is not null or undefined
            if (eventCategoryGuid === null || eventCategoryGuid === undefined) {
                throw new RequiredError('eventCategoryGuid','Required parameter eventCategoryGuid was null or undefined when calling eventCategoriesGetEventCategory.');
            }
            const localVarPath = `/vcr/api/eventcategory/{eventCategoryGuid}`
                .replace(`{${"eventCategoryGuid"}}`, encodeURIComponent(String(eventCategoryGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventCategoriesApi - functional programming interface
 * @export
 */
export const EventCategoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategories(cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EventCategory>> {
            const localVarFetchArgs = EventCategoriesApiFetchParamCreator(configuration).eventCategoriesGetEventCategories(cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} eventCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategory(eventCategoryGuid: string, cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EventCategory> {
            const localVarFetchArgs = EventCategoriesApiFetchParamCreator(configuration).eventCategoriesGetEventCategory(eventCategoryGuid, cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EventCategoriesApi - factory interface
 * @export
 */
export const EventCategoriesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategories(cultureCode?: string, authorization?: string, options?: any) {
            return EventCategoriesApiFp(configuration).eventCategoriesGetEventCategories(cultureCode, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} eventCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategory(eventCategoryGuid: string, cultureCode?: string, authorization?: string, options?: any) {
            return EventCategoriesApiFp(configuration).eventCategoriesGetEventCategory(eventCategoryGuid, cultureCode, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * EventCategoriesApi - object-oriented interface
 * @export
 * @class EventCategoriesApi
 * @extends {BaseAPI}
 */
export class EventCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventCategoriesApi
     */
    public eventCategoriesGetEventCategories(cultureCode?: string, authorization?: string, options?: any) {
        return EventCategoriesApiFp(this.configuration).eventCategoriesGetEventCategories(cultureCode, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} eventCategoryGuid 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventCategoriesApi
     */
    public eventCategoriesGetEventCategory(eventCategoryGuid: string, cultureCode?: string, authorization?: string, options?: any) {
        return EventCategoriesApiFp(this.configuration).eventCategoriesGetEventCategory(eventCategoryGuid, cultureCode, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * ForeignRepresentationsApi - fetch parameter creator
 * @export
 */
export const ForeignRepresentationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foreignRepresentationsGetForeignRepresentations(authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/foreignreps`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} foreignRepGuid 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foreignRepresentationsGetForeignRepresentations_1(foreignRepGuid: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'foreignRepGuid' is not null or undefined
            if (foreignRepGuid === null || foreignRepGuid === undefined) {
                throw new RequiredError('foreignRepGuid','Required parameter foreignRepGuid was null or undefined when calling foreignRepresentationsGetForeignRepresentations_1.');
            }
            const localVarPath = `/vcr/api/foreignrep`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (foreignRepGuid !== undefined) {
                localVarQueryParameter['foreignRepGuid'] = foreignRepGuid;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForeignRepresentationsApi - functional programming interface
 * @export
 */
export const ForeignRepresentationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foreignRepresentationsGetForeignRepresentations(authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ForeignRepresentation>> {
            const localVarFetchArgs = ForeignRepresentationsApiFetchParamCreator(configuration).foreignRepresentationsGetForeignRepresentations(authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} foreignRepGuid 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foreignRepresentationsGetForeignRepresentations_1(foreignRepGuid: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ForeignRepresentation> {
            const localVarFetchArgs = ForeignRepresentationsApiFetchParamCreator(configuration).foreignRepresentationsGetForeignRepresentations_1(foreignRepGuid, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ForeignRepresentationsApi - factory interface
 * @export
 */
export const ForeignRepresentationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foreignRepresentationsGetForeignRepresentations(authorization?: string, options?: any) {
            return ForeignRepresentationsApiFp(configuration).foreignRepresentationsGetForeignRepresentations(authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} foreignRepGuid 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foreignRepresentationsGetForeignRepresentations_1(foreignRepGuid: string, authorization?: string, options?: any) {
            return ForeignRepresentationsApiFp(configuration).foreignRepresentationsGetForeignRepresentations_1(foreignRepGuid, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * ForeignRepresentationsApi - object-oriented interface
 * @export
 * @class ForeignRepresentationsApi
 * @extends {BaseAPI}
 */
export class ForeignRepresentationsApi extends BaseAPI {
    /**
     * 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForeignRepresentationsApi
     */
    public foreignRepresentationsGetForeignRepresentations(authorization?: string, options?: any) {
        return ForeignRepresentationsApiFp(this.configuration).foreignRepresentationsGetForeignRepresentations(authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} foreignRepGuid 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForeignRepresentationsApi
     */
    public foreignRepresentationsGetForeignRepresentations_1(foreignRepGuid: string, authorization?: string, options?: any) {
        return ForeignRepresentationsApiFp(this.configuration).foreignRepresentationsGetForeignRepresentations_1(foreignRepGuid, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * MapPinsApi - fetch parameter creator
 * @export
 */
export const MapPinsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {number} [radius] 
         * @param {string} [pageType] 
         * @param {string} [categoryGuid] 
         * @param {string} [subCategoryGuid] 
         * @param {string} [regionGuid] 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapPinsGetMapPins(latitude: number, longitude: number, radius?: number, pageType?: string, categoryGuid?: string, subCategoryGuid?: string, regionGuid?: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'latitude' is not null or undefined
            if (latitude === null || latitude === undefined) {
                throw new RequiredError('latitude','Required parameter latitude was null or undefined when calling mapPinsGetMapPins.');
            }
            // verify required parameter 'longitude' is not null or undefined
            if (longitude === null || longitude === undefined) {
                throw new RequiredError('longitude','Required parameter longitude was null or undefined when calling mapPinsGetMapPins.');
            }
            const localVarPath = `/vcr/api/map/pin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (pageType !== undefined) {
                localVarQueryParameter['pageType'] = pageType;
            }

            if (categoryGuid !== undefined) {
                localVarQueryParameter['categoryGuid'] = categoryGuid;
            }

            if (subCategoryGuid !== undefined) {
                localVarQueryParameter['subCategoryGuid'] = subCategoryGuid;
            }

            if (regionGuid !== undefined) {
                localVarQueryParameter['regionGuid'] = regionGuid;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MapPinsApi - functional programming interface
 * @export
 */
export const MapPinsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {number} [radius] 
         * @param {string} [pageType] 
         * @param {string} [categoryGuid] 
         * @param {string} [subCategoryGuid] 
         * @param {string} [regionGuid] 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapPinsGetMapPins(latitude: number, longitude: number, radius?: number, pageType?: string, categoryGuid?: string, subCategoryGuid?: string, regionGuid?: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MapPin>> {
            const localVarFetchArgs = MapPinsApiFetchParamCreator(configuration).mapPinsGetMapPins(latitude, longitude, radius, pageType, categoryGuid, subCategoryGuid, regionGuid, cultureCode, isPreview, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MapPinsApi - factory interface
 * @export
 */
export const MapPinsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {number} [radius] 
         * @param {string} [pageType] 
         * @param {string} [categoryGuid] 
         * @param {string} [subCategoryGuid] 
         * @param {string} [regionGuid] 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapPinsGetMapPins(latitude: number, longitude: number, radius?: number, pageType?: string, categoryGuid?: string, subCategoryGuid?: string, regionGuid?: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
            return MapPinsApiFp(configuration).mapPinsGetMapPins(latitude, longitude, radius, pageType, categoryGuid, subCategoryGuid, regionGuid, cultureCode, isPreview, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * MapPinsApi - object-oriented interface
 * @export
 * @class MapPinsApi
 * @extends {BaseAPI}
 */
export class MapPinsApi extends BaseAPI {
    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {number} [radius] 
     * @param {string} [pageType] 
     * @param {string} [categoryGuid] 
     * @param {string} [subCategoryGuid] 
     * @param {string} [regionGuid] 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapPinsApi
     */
    public mapPinsGetMapPins(latitude: number, longitude: number, radius?: number, pageType?: string, categoryGuid?: string, subCategoryGuid?: string, regionGuid?: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
        return MapPinsApiFp(this.configuration).mapPinsGetMapPins(latitude, longitude, radius, pageType, categoryGuid, subCategoryGuid, regionGuid, cultureCode, isPreview, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * MeatSpaceWrapperApi - fetch parameter creator
 * @export
 */
export const MeatSpaceWrapperApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {number} [cache] 
         * @param {string} [lang] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperGet(meatSpacePath: string, cache?: number, lang?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'meatSpacePath' is not null or undefined
            if (meatSpacePath === null || meatSpacePath === undefined) {
                throw new RequiredError('meatSpacePath','Required parameter meatSpacePath was null or undefined when calling meatSpaceWrapperGet.');
            }
            const localVarPath = `/vcr/api/meatspacewrapper/get/{meatSpacePath}`
                .replace(`{${"meatSpacePath"}}`, encodeURIComponent(String(meatSpacePath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {string} data 
         * @param {string} [lang] 
         * @param {string} [recaptchaToken] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperPost(meatSpacePath: string, data: string, lang?: string, recaptchaToken?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'meatSpacePath' is not null or undefined
            if (meatSpacePath === null || meatSpacePath === undefined) {
                throw new RequiredError('meatSpacePath','Required parameter meatSpacePath was null or undefined when calling meatSpaceWrapperPost.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling meatSpaceWrapperPost.');
            }
            const localVarPath = `/vcr/api/meatspacewrapper/post/{meatSpacePath}`
                .replace(`{${"meatSpacePath"}}`, encodeURIComponent(String(meatSpacePath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (recaptchaToken !== undefined) {
                localVarQueryParameter['recaptchaToken'] = recaptchaToken;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeatSpaceWrapperApi - functional programming interface
 * @export
 */
export const MeatSpaceWrapperApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {number} [cache] 
         * @param {string} [lang] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperGet(meatSpacePath: string, cache?: number, lang?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MeatSpaceWrapperApiFetchParamCreator(configuration).meatSpaceWrapperGet(meatSpacePath, cache, lang, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {string} data 
         * @param {string} [lang] 
         * @param {string} [recaptchaToken] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperPost(meatSpacePath: string, data: string, lang?: string, recaptchaToken?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MeatSpaceWrapperApiFetchParamCreator(configuration).meatSpaceWrapperPost(meatSpacePath, data, lang, recaptchaToken, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MeatSpaceWrapperApi - factory interface
 * @export
 */
export const MeatSpaceWrapperApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {number} [cache] 
         * @param {string} [lang] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperGet(meatSpacePath: string, cache?: number, lang?: string, authorization?: string, options?: any) {
            return MeatSpaceWrapperApiFp(configuration).meatSpaceWrapperGet(meatSpacePath, cache, lang, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {string} data 
         * @param {string} [lang] 
         * @param {string} [recaptchaToken] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperPost(meatSpacePath: string, data: string, lang?: string, recaptchaToken?: string, authorization?: string, options?: any) {
            return MeatSpaceWrapperApiFp(configuration).meatSpaceWrapperPost(meatSpacePath, data, lang, recaptchaToken, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * MeatSpaceWrapperApi - object-oriented interface
 * @export
 * @class MeatSpaceWrapperApi
 * @extends {BaseAPI}
 */
export class MeatSpaceWrapperApi extends BaseAPI {
    /**
     * 
     * @param {string} meatSpacePath 
     * @param {number} [cache] 
     * @param {string} [lang] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeatSpaceWrapperApi
     */
    public meatSpaceWrapperGet(meatSpacePath: string, cache?: number, lang?: string, authorization?: string, options?: any) {
        return MeatSpaceWrapperApiFp(this.configuration).meatSpaceWrapperGet(meatSpacePath, cache, lang, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} meatSpacePath 
     * @param {string} data 
     * @param {string} [lang] 
     * @param {string} [recaptchaToken] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeatSpaceWrapperApi
     */
    public meatSpaceWrapperPost(meatSpacePath: string, data: string, lang?: string, recaptchaToken?: string, authorization?: string, options?: any) {
        return MeatSpaceWrapperApiFp(this.configuration).meatSpaceWrapperPost(meatSpacePath, data, lang, recaptchaToken, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * NavigationsApi - fetch parameter creator
 * @export
 */
export const NavigationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling navigationsGetNavigation.');
            }
            const localVarPath = `/vcr/api/navigation/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigations(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling navigationsGetNavigations.');
            }
            const localVarPath = `/vcr/api/navigations/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNearestParentNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling navigationsGetNearestParentNavigation.');
            }
            const localVarPath = `/vcr/api/navigation/nearest/parent/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NavigationsApi - functional programming interface
 * @export
 */
export const NavigationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NavigationFolder> {
            const localVarFetchArgs = NavigationsApiFetchParamCreator(configuration).navigationsGetNavigation(nodeAliasPath, cultureCode, isPreview, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigations(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NavigationFolder>> {
            const localVarFetchArgs = NavigationsApiFetchParamCreator(configuration).navigationsGetNavigations(nodeAliasPath, cultureCode, isPreview, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNearestParentNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NavigationFolder> {
            const localVarFetchArgs = NavigationsApiFetchParamCreator(configuration).navigationsGetNearestParentNavigation(nodeAliasPath, cultureCode, isPreview, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NavigationsApi - factory interface
 * @export
 */
export const NavigationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
            return NavigationsApiFp(configuration).navigationsGetNavigation(nodeAliasPath, cultureCode, isPreview, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigations(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
            return NavigationsApiFp(configuration).navigationsGetNavigations(nodeAliasPath, cultureCode, isPreview, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNearestParentNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
            return NavigationsApiFp(configuration).navigationsGetNearestParentNavigation(nodeAliasPath, cultureCode, isPreview, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * NavigationsApi - object-oriented interface
 * @export
 * @class NavigationsApi
 * @extends {BaseAPI}
 */
export class NavigationsApi extends BaseAPI {
    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationsApi
     */
    public navigationsGetNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
        return NavigationsApiFp(this.configuration).navigationsGetNavigation(nodeAliasPath, cultureCode, isPreview, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationsApi
     */
    public navigationsGetNavigations(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
        return NavigationsApiFp(this.configuration).navigationsGetNavigations(nodeAliasPath, cultureCode, isPreview, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationsApi
     */
    public navigationsGetNearestParentNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
        return NavigationsApiFp(this.configuration).navigationsGetNearestParentNavigation(nodeAliasPath, cultureCode, isPreview, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * NewsletterApi - fetch parameter creator
 * @export
 */
export const NewsletterApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} newsletterName 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletter(newsletterName: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'newsletterName' is not null or undefined
            if (newsletterName === null || newsletterName === undefined) {
                throw new RequiredError('newsletterName','Required parameter newsletterName was null or undefined when calling newsletterGetNewsletter.');
            }
            const localVarPath = `/vcr/api/newsletter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (newsletterName !== undefined) {
                localVarQueryParameter['newsletterName'] = newsletterName;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [codenameStartsWith] 
         * @param {string} [filterByNodeAliasPath] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletters(codenameStartsWith?: string, filterByNodeAliasPath?: string, authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/newsletters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codenameStartsWith !== undefined) {
                localVarQueryParameter['codenameStartsWith'] = codenameStartsWith;
            }

            if (filterByNodeAliasPath !== undefined) {
                localVarQueryParameter['filterByNodeAliasPath'] = filterByNodeAliasPath;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewsletterContact} contact 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterSubscribe(contact: NewsletterContact, xAPIKey: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'contact' is not null or undefined
            if (contact === null || contact === undefined) {
                throw new RequiredError('contact','Required parameter contact was null or undefined when calling newsletterSubscribe.');
            }
            // verify required parameter 'xAPIKey' is not null or undefined
            if (xAPIKey === null || xAPIKey === undefined) {
                throw new RequiredError('xAPIKey','Required parameter xAPIKey was null or undefined when calling newsletterSubscribe.');
            }
            const localVarPath = `/vcr/api/newsletter/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xAPIKey !== undefined && xAPIKey !== null) {
                localVarHeaderParameter['X-API-Key'] = String(xAPIKey);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewsletterContact" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(contact || {}) : (contact || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsletterApi - functional programming interface
 * @export
 */
export const NewsletterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} newsletterName 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletter(newsletterName: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NewsletterInfo> {
            const localVarFetchArgs = NewsletterApiFetchParamCreator(configuration).newsletterGetNewsletter(newsletterName, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [codenameStartsWith] 
         * @param {string} [filterByNodeAliasPath] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletters(codenameStartsWith?: string, filterByNodeAliasPath?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NewsletterInfo>> {
            const localVarFetchArgs = NewsletterApiFetchParamCreator(configuration).newsletterGetNewsletters(codenameStartsWith, filterByNodeAliasPath, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {NewsletterContact} contact 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterSubscribe(contact: NewsletterContact, xAPIKey: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = NewsletterApiFetchParamCreator(configuration).newsletterSubscribe(contact, xAPIKey, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NewsletterApi - factory interface
 * @export
 */
export const NewsletterApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} newsletterName 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletter(newsletterName: string, authorization?: string, options?: any) {
            return NewsletterApiFp(configuration).newsletterGetNewsletter(newsletterName, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [codenameStartsWith] 
         * @param {string} [filterByNodeAliasPath] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletters(codenameStartsWith?: string, filterByNodeAliasPath?: string, authorization?: string, options?: any) {
            return NewsletterApiFp(configuration).newsletterGetNewsletters(codenameStartsWith, filterByNodeAliasPath, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {NewsletterContact} contact 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterSubscribe(contact: NewsletterContact, xAPIKey: string, authorization?: string, options?: any) {
            return NewsletterApiFp(configuration).newsletterSubscribe(contact, xAPIKey, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * NewsletterApi - object-oriented interface
 * @export
 * @class NewsletterApi
 * @extends {BaseAPI}
 */
export class NewsletterApi extends BaseAPI {
    /**
     * 
     * @param {string} newsletterName 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public newsletterGetNewsletter(newsletterName: string, authorization?: string, options?: any) {
        return NewsletterApiFp(this.configuration).newsletterGetNewsletter(newsletterName, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [codenameStartsWith] 
     * @param {string} [filterByNodeAliasPath] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public newsletterGetNewsletters(codenameStartsWith?: string, filterByNodeAliasPath?: string, authorization?: string, options?: any) {
        return NewsletterApiFp(this.configuration).newsletterGetNewsletters(codenameStartsWith, filterByNodeAliasPath, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {NewsletterContact} contact 
     * @param {string} xAPIKey ApiKey header
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public newsletterSubscribe(contact: NewsletterContact, xAPIKey: string, authorization?: string, options?: any) {
        return NewsletterApiFp(this.configuration).newsletterSubscribe(contact, xAPIKey, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * PagesApi - fetch parameter creator
 * @export
 */
export const PagesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPage(nodeAliasPath: string, cultureCode?: string, className?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling pagesGetPage.');
            }
            const localVarPath = `/vcr/api/page/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (className !== undefined) {
                localVarQueryParameter['className'] = className;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (withNavData !== undefined) {
                localVarQueryParameter['withNavData'] = withNavData;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPageByGuid(nodeGuid: string, cultureCode?: string, className?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling pagesGetPageByGuid.');
            }
            const localVarPath = `/vcr/api/page/{nodeGuid}/`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (className !== undefined) {
                localVarQueryParameter['className'] = className;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (withNavData !== undefined) {
                localVarQueryParameter['withNavData'] = withNavData;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPages(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling pagesGetPages.');
            }
            const localVarPath = `/vcr/api/pages/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (withNavData !== undefined) {
                localVarQueryParameter['withNavData'] = withNavData;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPagesByGuid(nodeGuid: string, cultureCode?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling pagesGetPagesByGuid.');
            }
            const localVarPath = `/vcr/api/pages/{nodeGuid}/`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (withNavData !== undefined) {
                localVarQueryParameter['withNavData'] = withNavData;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PagesApi - functional programming interface
 * @export
 */
export const PagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPage(nodeAliasPath: string, cultureCode?: string, className?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = PagesApiFetchParamCreator(configuration).pagesGetPage(nodeAliasPath, cultureCode, className, isPreview, withNavData, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPageByGuid(nodeGuid: string, cultureCode?: string, className?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BasePage> {
            const localVarFetchArgs = PagesApiFetchParamCreator(configuration).pagesGetPageByGuid(nodeGuid, cultureCode, className, isPreview, withNavData, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPages(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BasePage>> {
            const localVarFetchArgs = PagesApiFetchParamCreator(configuration).pagesGetPages(nodeAliasPath, cultureCode, isPreview, withNavData, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPagesByGuid(nodeGuid: string, cultureCode?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BasePage>> {
            const localVarFetchArgs = PagesApiFetchParamCreator(configuration).pagesGetPagesByGuid(nodeGuid, cultureCode, isPreview, withNavData, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PagesApi - factory interface
 * @export
 */
export const PagesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPage(nodeAliasPath: string, cultureCode?: string, className?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any) {
            return PagesApiFp(configuration).pagesGetPage(nodeAliasPath, cultureCode, className, isPreview, withNavData, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPageByGuid(nodeGuid: string, cultureCode?: string, className?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any) {
            return PagesApiFp(configuration).pagesGetPageByGuid(nodeGuid, cultureCode, className, isPreview, withNavData, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPages(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any) {
            return PagesApiFp(configuration).pagesGetPages(nodeAliasPath, cultureCode, isPreview, withNavData, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {boolean} [withNavData] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPagesByGuid(nodeGuid: string, cultureCode?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any) {
            return PagesApiFp(configuration).pagesGetPagesByGuid(nodeGuid, cultureCode, isPreview, withNavData, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * PagesApi - object-oriented interface
 * @export
 * @class PagesApi
 * @extends {BaseAPI}
 */
export class PagesApi extends BaseAPI {
    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {string} [className] 
     * @param {boolean} [isPreview] 
     * @param {boolean} [withNavData] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public pagesGetPage(nodeAliasPath: string, cultureCode?: string, className?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any) {
        return PagesApiFp(this.configuration).pagesGetPage(nodeAliasPath, cultureCode, className, isPreview, withNavData, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeGuid 
     * @param {string} [cultureCode] 
     * @param {string} [className] 
     * @param {boolean} [isPreview] 
     * @param {boolean} [withNavData] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public pagesGetPageByGuid(nodeGuid: string, cultureCode?: string, className?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any) {
        return PagesApiFp(this.configuration).pagesGetPageByGuid(nodeGuid, cultureCode, className, isPreview, withNavData, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {boolean} [withNavData] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public pagesGetPages(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any) {
        return PagesApiFp(this.configuration).pagesGetPages(nodeAliasPath, cultureCode, isPreview, withNavData, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeGuid 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {boolean} [withNavData] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public pagesGetPagesByGuid(nodeGuid: string, cultureCode?: string, isPreview?: boolean, withNavData?: boolean, authorization?: string, options?: any) {
        return PagesApiFp(this.configuration).pagesGetPagesByGuid(nodeGuid, cultureCode, isPreview, withNavData, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * RegionsApi - fetch parameter creator
 * @export
 */
export const RegionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} regionGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegion(regionGuid: string, cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'regionGuid' is not null or undefined
            if (regionGuid === null || regionGuid === undefined) {
                throw new RequiredError('regionGuid','Required parameter regionGuid was null or undefined when calling regionsGetRegion.');
            }
            const localVarPath = `/vcr/api/region/{regionGuid}`
                .replace(`{${"regionGuid"}}`, encodeURIComponent(String(regionGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegions(cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/regions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionsApi - functional programming interface
 * @export
 */
export const RegionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} regionGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegion(regionGuid: string, cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Region> {
            const localVarFetchArgs = RegionsApiFetchParamCreator(configuration).regionsGetRegion(regionGuid, cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegions(cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Region>> {
            const localVarFetchArgs = RegionsApiFetchParamCreator(configuration).regionsGetRegions(cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RegionsApi - factory interface
 * @export
 */
export const RegionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} regionGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegion(regionGuid: string, cultureCode?: string, authorization?: string, options?: any) {
            return RegionsApiFp(configuration).regionsGetRegion(regionGuid, cultureCode, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegions(cultureCode?: string, authorization?: string, options?: any) {
            return RegionsApiFp(configuration).regionsGetRegions(cultureCode, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * RegionsApi - object-oriented interface
 * @export
 * @class RegionsApi
 * @extends {BaseAPI}
 */
export class RegionsApi extends BaseAPI {
    /**
     * 
     * @param {string} regionGuid 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public regionsGetRegion(regionGuid: string, cultureCode?: string, authorization?: string, options?: any) {
        return RegionsApiFp(this.configuration).regionsGetRegion(regionGuid, cultureCode, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public regionsGetRegions(cultureCode?: string, authorization?: string, options?: any) {
        return RegionsApiFp(this.configuration).regionsGetRegions(cultureCode, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * ResourcesApi - fetch parameter creator
 * @export
 */
export const ResourcesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} resourceKey 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResource(resourceKey: string, cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'resourceKey' is not null or undefined
            if (resourceKey === null || resourceKey === undefined) {
                throw new RequiredError('resourceKey','Required parameter resourceKey was null or undefined when calling resourcesGetResource.');
            }
            const localVarPath = `/vcr/api/resource`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resourceKey !== undefined) {
                localVarQueryParameter['resourceKey'] = resourceKey;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResources(cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/resources`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourcesApi - functional programming interface
 * @export
 */
export const ResourcesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} resourceKey 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResource(resourceKey: string, cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResourceString> {
            const localVarFetchArgs = ResourcesApiFetchParamCreator(configuration).resourcesGetResource(resourceKey, cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResources(cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ResourceString>> {
            const localVarFetchArgs = ResourcesApiFetchParamCreator(configuration).resourcesGetResources(cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ResourcesApi - factory interface
 * @export
 */
export const ResourcesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} resourceKey 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResource(resourceKey: string, cultureCode?: string, authorization?: string, options?: any) {
            return ResourcesApiFp(configuration).resourcesGetResource(resourceKey, cultureCode, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResources(cultureCode?: string, authorization?: string, options?: any) {
            return ResourcesApiFp(configuration).resourcesGetResources(cultureCode, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * ResourcesApi - object-oriented interface
 * @export
 * @class ResourcesApi
 * @extends {BaseAPI}
 */
export class ResourcesApi extends BaseAPI {
    /**
     * 
     * @param {string} resourceKey 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public resourcesGetResource(resourceKey: string, cultureCode?: string, authorization?: string, options?: any) {
        return ResourcesApiFp(this.configuration).resourcesGetResource(resourceKey, cultureCode, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public resourcesGetResources(cultureCode?: string, authorization?: string, options?: any) {
        return ResourcesApiFp(this.configuration).resourcesGetResources(cultureCode, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * SafeTravelStampsApi - fetch parameter creator
 * @export
 */
export const SafeTravelStampsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} formId 
         * @param {string} id 
         * @param {string} [cultureCode] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItem(formId: number, id: string, cultureCode?: string, cache?: number, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'formId' is not null or undefined
            if (formId === null || formId === undefined) {
                throw new RequiredError('formId','Required parameter formId was null or undefined when calling safeTravelStampsGetItem.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling safeTravelStampsGetItem.');
            }
            const localVarPath = `/vcr/api/safetravelstamp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (formId !== undefined) {
                localVarQueryParameter['formId'] = formId;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItems(cultureCode?: string, cache?: number, authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/safetravelstamps`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SafeTravelStampsApi - functional programming interface
 * @export
 */
export const SafeTravelStampsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} formId 
         * @param {string} id 
         * @param {string} [cultureCode] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItem(formId: number, id: string, cultureCode?: string, cache?: number, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = SafeTravelStampsApiFetchParamCreator(configuration).safeTravelStampsGetItem(formId, id, cultureCode, cache, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItems(cultureCode?: string, cache?: number, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = SafeTravelStampsApiFetchParamCreator(configuration).safeTravelStampsGetItems(cultureCode, cache, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SafeTravelStampsApi - factory interface
 * @export
 */
export const SafeTravelStampsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} formId 
         * @param {string} id 
         * @param {string} [cultureCode] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItem(formId: number, id: string, cultureCode?: string, cache?: number, authorization?: string, options?: any) {
            return SafeTravelStampsApiFp(configuration).safeTravelStampsGetItem(formId, id, cultureCode, cache, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItems(cultureCode?: string, cache?: number, authorization?: string, options?: any) {
            return SafeTravelStampsApiFp(configuration).safeTravelStampsGetItems(cultureCode, cache, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * SafeTravelStampsApi - object-oriented interface
 * @export
 * @class SafeTravelStampsApi
 * @extends {BaseAPI}
 */
export class SafeTravelStampsApi extends BaseAPI {
    /**
     * 
     * @param {number} formId 
     * @param {string} id 
     * @param {string} [cultureCode] 
     * @param {number} [cache] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeTravelStampsApi
     */
    public safeTravelStampsGetItem(formId: number, id: string, cultureCode?: string, cache?: number, authorization?: string, options?: any) {
        return SafeTravelStampsApiFp(this.configuration).safeTravelStampsGetItem(formId, id, cultureCode, cache, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [cultureCode] 
     * @param {number} [cache] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeTravelStampsApi
     */
    public safeTravelStampsGetItems(cultureCode?: string, cache?: number, authorization?: string, options?: any) {
        return SafeTravelStampsApiFp(this.configuration).safeTravelStampsGetItems(cultureCode, cache, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * SearchApi - fetch parameter creator
 * @export
 */
export const SearchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} searchTerm 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [cultureCode] 
         * @param {string} [path] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetSearchResult(searchTerm: string, pageNum?: number, pageSize?: number, cultureCode?: string, path?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'searchTerm' is not null or undefined
            if (searchTerm === null || searchTerm === undefined) {
                throw new RequiredError('searchTerm','Required parameter searchTerm was null or undefined when calling searchGetSearchResult.');
            }
            const localVarPath = `/vcr/api/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} searchTerm 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [cultureCode] 
         * @param {string} [path] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetSearchResult(searchTerm: string, pageNum?: number, pageSize?: number, cultureCode?: string, path?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchResults> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchGetSearchResult(searchTerm, pageNum, pageSize, cultureCode, path, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} searchTerm 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [cultureCode] 
         * @param {string} [path] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetSearchResult(searchTerm: string, pageNum?: number, pageSize?: number, cultureCode?: string, path?: string, authorization?: string, options?: any) {
            return SearchApiFp(configuration).searchGetSearchResult(searchTerm, pageNum, pageSize, cultureCode, path, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {string} searchTerm 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [cultureCode] 
     * @param {string} [path] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchGetSearchResult(searchTerm: string, pageNum?: number, pageSize?: number, cultureCode?: string, path?: string, authorization?: string, options?: any) {
        return SearchApiFp(this.configuration).searchGetSearchResult(searchTerm, pageNum, pageSize, cultureCode, path, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * SiteCulturesApi - fetch parameter creator
 * @export
 */
export const SiteCulturesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cultureCode 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCulture(cultureCode: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'cultureCode' is not null or undefined
            if (cultureCode === null || cultureCode === undefined) {
                throw new RequiredError('cultureCode','Required parameter cultureCode was null or undefined when calling siteCulturesGetSiteCulture.');
            }
            const localVarPath = `/vcr/api/siteculture`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCultures(authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/sitecultures`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteCulturesApi - functional programming interface
 * @export
 */
export const SiteCulturesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cultureCode 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCulture(cultureCode: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteCulture> {
            const localVarFetchArgs = SiteCulturesApiFetchParamCreator(configuration).siteCulturesGetSiteCulture(cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCultures(authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SiteCulture>> {
            const localVarFetchArgs = SiteCulturesApiFetchParamCreator(configuration).siteCulturesGetSiteCultures(authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SiteCulturesApi - factory interface
 * @export
 */
export const SiteCulturesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} cultureCode 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCulture(cultureCode: string, authorization?: string, options?: any) {
            return SiteCulturesApiFp(configuration).siteCulturesGetSiteCulture(cultureCode, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCultures(authorization?: string, options?: any) {
            return SiteCulturesApiFp(configuration).siteCulturesGetSiteCultures(authorization, options)(fetch, basePath);
        },
    };
};

/**
 * SiteCulturesApi - object-oriented interface
 * @export
 * @class SiteCulturesApi
 * @extends {BaseAPI}
 */
export class SiteCulturesApi extends BaseAPI {
    /**
     * 
     * @param {string} cultureCode 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteCulturesApi
     */
    public siteCulturesGetSiteCulture(cultureCode: string, authorization?: string, options?: any) {
        return SiteCulturesApiFp(this.configuration).siteCulturesGetSiteCulture(cultureCode, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteCulturesApi
     */
    public siteCulturesGetSiteCultures(authorization?: string, options?: any) {
        return SiteCulturesApiFp(this.configuration).siteCulturesGetSiteCultures(authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * SubCategoriesApi - fetch parameter creator
 * @export
 */
export const SubCategoriesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategories(categoryGuid: string, cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'categoryGuid' is not null or undefined
            if (categoryGuid === null || categoryGuid === undefined) {
                throw new RequiredError('categoryGuid','Required parameter categoryGuid was null or undefined when calling subCategoriesGetSubCategories.');
            }
            const localVarPath = `/vcr/api/subcategories/{categoryGuid}`
                .replace(`{${"categoryGuid"}}`, encodeURIComponent(String(categoryGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategory(subCategoryGuid: string, cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'subCategoryGuid' is not null or undefined
            if (subCategoryGuid === null || subCategoryGuid === undefined) {
                throw new RequiredError('subCategoryGuid','Required parameter subCategoryGuid was null or undefined when calling subCategoriesGetSubCategory.');
            }
            const localVarPath = `/vcr/api/subcategory/{subCategoryGuid}`
                .replace(`{${"subCategoryGuid"}}`, encodeURIComponent(String(subCategoryGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubCategoriesApi - functional programming interface
 * @export
 */
export const SubCategoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategories(categoryGuid: string, cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubCategory>> {
            const localVarFetchArgs = SubCategoriesApiFetchParamCreator(configuration).subCategoriesGetSubCategories(categoryGuid, cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} subCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategory(subCategoryGuid: string, cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubCategory> {
            const localVarFetchArgs = SubCategoriesApiFetchParamCreator(configuration).subCategoriesGetSubCategory(subCategoryGuid, cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SubCategoriesApi - factory interface
 * @export
 */
export const SubCategoriesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategories(categoryGuid: string, cultureCode?: string, authorization?: string, options?: any) {
            return SubCategoriesApiFp(configuration).subCategoriesGetSubCategories(categoryGuid, cultureCode, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} subCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategory(subCategoryGuid: string, cultureCode?: string, authorization?: string, options?: any) {
            return SubCategoriesApiFp(configuration).subCategoriesGetSubCategory(subCategoryGuid, cultureCode, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * SubCategoriesApi - object-oriented interface
 * @export
 * @class SubCategoriesApi
 * @extends {BaseAPI}
 */
export class SubCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {string} categoryGuid 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubCategoriesApi
     */
    public subCategoriesGetSubCategories(categoryGuid: string, cultureCode?: string, authorization?: string, options?: any) {
        return SubCategoriesApiFp(this.configuration).subCategoriesGetSubCategories(categoryGuid, cultureCode, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} subCategoryGuid 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubCategoriesApi
     */
    public subCategoriesGetSubCategory(subCategoryGuid: string, cultureCode?: string, authorization?: string, options?: any) {
        return SubCategoriesApiFp(this.configuration).subCategoriesGetSubCategory(subCategoryGuid, cultureCode, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * TranslationsApi - fetch parameter creator
 * @export
 */
export const TranslationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AutomatedTranslationVoting} voting 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsAutomatedTranslationVoting(voting: AutomatedTranslationVoting, xAPIKey: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'voting' is not null or undefined
            if (voting === null || voting === undefined) {
                throw new RequiredError('voting','Required parameter voting was null or undefined when calling translationsAutomatedTranslationVoting.');
            }
            // verify required parameter 'xAPIKey' is not null or undefined
            if (xAPIKey === null || xAPIKey === undefined) {
                throw new RequiredError('xAPIKey','Required parameter xAPIKey was null or undefined when calling translationsAutomatedTranslationVoting.');
            }
            const localVarPath = `/vcr/api/translations/voting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xAPIKey !== undefined && xAPIKey !== null) {
                localVarHeaderParameter['X-API-Key'] = String(xAPIKey);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AutomatedTranslationVoting" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(voting || {}) : (voting || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationsApi - functional programming interface
 * @export
 */
export const TranslationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AutomatedTranslationVoting} voting 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsAutomatedTranslationVoting(voting: AutomatedTranslationVoting, xAPIKey: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VotingResponse> {
            const localVarFetchArgs = TranslationsApiFetchParamCreator(configuration).translationsAutomatedTranslationVoting(voting, xAPIKey, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TranslationsApi - factory interface
 * @export
 */
export const TranslationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AutomatedTranslationVoting} voting 
         * @param {string} xAPIKey ApiKey header
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsAutomatedTranslationVoting(voting: AutomatedTranslationVoting, xAPIKey: string, authorization?: string, options?: any) {
            return TranslationsApiFp(configuration).translationsAutomatedTranslationVoting(voting, xAPIKey, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * TranslationsApi - object-oriented interface
 * @export
 * @class TranslationsApi
 * @extends {BaseAPI}
 */
export class TranslationsApi extends BaseAPI {
    /**
     * 
     * @param {AutomatedTranslationVoting} voting 
     * @param {string} xAPIKey ApiKey header
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public translationsAutomatedTranslationVoting(voting: AutomatedTranslationVoting, xAPIKey: string, authorization?: string, options?: any) {
        return TranslationsApiFp(this.configuration).translationsAutomatedTranslationVoting(voting, xAPIKey, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * WhatsHotApi - fetch parameter creator
 * @export
 */
export const WhatsHotApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whatsHotGetItem(nodeGuid: string, cultureCode?: string, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling whatsHotGetItem.');
            }
            const localVarPath = `/vcr/api/whatshot/detail/{nodeGuid}`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {Date} [dateModifiedFrom] 
         * @param {number} [pageNum] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whatsHotGetList(cultureCode?: string, dateModifiedFrom?: Date, pageNum?: number, authorization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/vcr/api/whatshot/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (dateModifiedFrom !== undefined) {
                localVarQueryParameter['dateModifiedFrom'] = (dateModifiedFrom as any).toISOString();
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhatsHotApi - functional programming interface
 * @export
 */
export const WhatsHotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whatsHotGetItem(nodeGuid: string, cultureCode?: string, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Detail> {
            const localVarFetchArgs = WhatsHotApiFetchParamCreator(configuration).whatsHotGetItem(nodeGuid, cultureCode, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {Date} [dateModifiedFrom] 
         * @param {number} [pageNum] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whatsHotGetList(cultureCode?: string, dateModifiedFrom?: Date, pageNum?: number, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageList> {
            const localVarFetchArgs = WhatsHotApiFetchParamCreator(configuration).whatsHotGetList(cultureCode, dateModifiedFrom, pageNum, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WhatsHotApi - factory interface
 * @export
 */
export const WhatsHotApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whatsHotGetItem(nodeGuid: string, cultureCode?: string, authorization?: string, options?: any) {
            return WhatsHotApiFp(configuration).whatsHotGetItem(nodeGuid, cultureCode, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {Date} [dateModifiedFrom] 
         * @param {number} [pageNum] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whatsHotGetList(cultureCode?: string, dateModifiedFrom?: Date, pageNum?: number, authorization?: string, options?: any) {
            return WhatsHotApiFp(configuration).whatsHotGetList(cultureCode, dateModifiedFrom, pageNum, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * WhatsHotApi - object-oriented interface
 * @export
 * @class WhatsHotApi
 * @extends {BaseAPI}
 */
export class WhatsHotApi extends BaseAPI {
    /**
     * 
     * @param {string} nodeGuid 
     * @param {string} [cultureCode] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatsHotApi
     */
    public whatsHotGetItem(nodeGuid: string, cultureCode?: string, authorization?: string, options?: any) {
        return WhatsHotApiFp(this.configuration).whatsHotGetItem(nodeGuid, cultureCode, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [cultureCode] 
     * @param {Date} [dateModifiedFrom] 
     * @param {number} [pageNum] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatsHotApi
     */
    public whatsHotGetList(cultureCode?: string, dateModifiedFrom?: Date, pageNum?: number, authorization?: string, options?: any) {
        return WhatsHotApiFp(this.configuration).whatsHotGetList(cultureCode, dateModifiedFrom, pageNum, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * WidgetsApi - fetch parameter creator
 * @export
 */
export const WidgetsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {string} [taxonomyGuids] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidget(nodeAliasPath: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, taxonomyGuids?: string, isPreview?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling widgetsGetWidget.');
            }
            const localVarPath = `/vcr/api/widget/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (parentPageGuid !== undefined) {
                localVarQueryParameter['parentPageGuid'] = parentPageGuid;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (className !== undefined) {
                localVarQueryParameter['className'] = className;
            }

            if (taxonomyGuids !== undefined) {
                localVarQueryParameter['taxonomyGuids'] = taxonomyGuids;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {string} [taxonomyGuids] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetByGuid(nodeGuid: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, taxonomyGuids?: string, isPreview?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling widgetsGetWidgetByGuid.');
            }
            const localVarPath = `/vcr/api/widget/{nodeGuid}/`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (parentPageGuid !== undefined) {
                localVarQueryParameter['parentPageGuid'] = parentPageGuid;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (className !== undefined) {
                localVarQueryParameter['className'] = className;
            }

            if (taxonomyGuids !== undefined) {
                localVarQueryParameter['taxonomyGuids'] = taxonomyGuids;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgets(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling widgetsGetWidgets.');
            }
            const localVarPath = `/vcr/api/widgets/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentNodeGuid 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetsByGuid(parentNodeGuid: string, nodeGuid: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'parentNodeGuid' is not null or undefined
            if (parentNodeGuid === null || parentNodeGuid === undefined) {
                throw new RequiredError('parentNodeGuid','Required parameter parentNodeGuid was null or undefined when calling widgetsGetWidgetsByGuid.');
            }
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling widgetsGetWidgetsByGuid.');
            }
            const localVarPath = `/vcr/api/widgets/{nodeGuid}/`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentNodeGuid !== undefined) {
                localVarQueryParameter['parentNodeGuid'] = parentNodeGuid;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetsApi - functional programming interface
 * @export
 */
export const WidgetsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {string} [taxonomyGuids] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidget(nodeAliasPath: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, taxonomyGuids?: string, isPreview?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BaseWidget> {
            const localVarFetchArgs = WidgetsApiFetchParamCreator(configuration).widgetsGetWidget(nodeAliasPath, pageNum, parentPageGuid, cultureCode, className, taxonomyGuids, isPreview, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {string} [taxonomyGuids] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetByGuid(nodeGuid: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, taxonomyGuids?: string, isPreview?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BaseWidget> {
            const localVarFetchArgs = WidgetsApiFetchParamCreator(configuration).widgetsGetWidgetByGuid(nodeGuid, pageNum, parentPageGuid, cultureCode, className, taxonomyGuids, isPreview, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgets(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BaseWidget>> {
            const localVarFetchArgs = WidgetsApiFetchParamCreator(configuration).widgetsGetWidgets(nodeAliasPath, cultureCode, isPreview, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} parentNodeGuid 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetsByGuid(parentNodeGuid: string, nodeGuid: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BaseWidget>> {
            const localVarFetchArgs = WidgetsApiFetchParamCreator(configuration).widgetsGetWidgetsByGuid(parentNodeGuid, nodeGuid, cultureCode, isPreview, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WidgetsApi - factory interface
 * @export
 */
export const WidgetsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {string} [taxonomyGuids] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidget(nodeAliasPath: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, taxonomyGuids?: string, isPreview?: boolean, authorization?: string, options?: any) {
            return WidgetsApiFp(configuration).widgetsGetWidget(nodeAliasPath, pageNum, parentPageGuid, cultureCode, className, taxonomyGuids, isPreview, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {string} [taxonomyGuids] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetByGuid(nodeGuid: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, taxonomyGuids?: string, isPreview?: boolean, authorization?: string, options?: any) {
            return WidgetsApiFp(configuration).widgetsGetWidgetByGuid(nodeGuid, pageNum, parentPageGuid, cultureCode, className, taxonomyGuids, isPreview, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgets(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
            return WidgetsApiFp(configuration).widgetsGetWidgets(nodeAliasPath, cultureCode, isPreview, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} parentNodeGuid 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetsByGuid(parentNodeGuid: string, nodeGuid: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
            return WidgetsApiFp(configuration).widgetsGetWidgetsByGuid(parentNodeGuid, nodeGuid, cultureCode, isPreview, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * WidgetsApi - object-oriented interface
 * @export
 * @class WidgetsApi
 * @extends {BaseAPI}
 */
export class WidgetsApi extends BaseAPI {
    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {number} [pageNum] 
     * @param {string} [parentPageGuid] 
     * @param {string} [cultureCode] 
     * @param {string} [className] 
     * @param {string} [taxonomyGuids] 
     * @param {boolean} [isPreview] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetsGetWidget(nodeAliasPath: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, taxonomyGuids?: string, isPreview?: boolean, authorization?: string, options?: any) {
        return WidgetsApiFp(this.configuration).widgetsGetWidget(nodeAliasPath, pageNum, parentPageGuid, cultureCode, className, taxonomyGuids, isPreview, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeGuid 
     * @param {number} [pageNum] 
     * @param {string} [parentPageGuid] 
     * @param {string} [cultureCode] 
     * @param {string} [className] 
     * @param {string} [taxonomyGuids] 
     * @param {boolean} [isPreview] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetsGetWidgetByGuid(nodeGuid: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, taxonomyGuids?: string, isPreview?: boolean, authorization?: string, options?: any) {
        return WidgetsApiFp(this.configuration).widgetsGetWidgetByGuid(nodeGuid, pageNum, parentPageGuid, cultureCode, className, taxonomyGuids, isPreview, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetsGetWidgets(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
        return WidgetsApiFp(this.configuration).widgetsGetWidgets(nodeAliasPath, cultureCode, isPreview, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} parentNodeGuid 
     * @param {string} nodeGuid 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetsGetWidgetsByGuid(parentNodeGuid: string, nodeGuid: string, cultureCode?: string, isPreview?: boolean, authorization?: string, options?: any) {
        return WidgetsApiFp(this.configuration).widgetsGetWidgetsByGuid(parentNodeGuid, nodeGuid, cultureCode, isPreview, authorization, options)(this.fetch, this.basePath);
    }

}

/**
 * YrNoWeatherWrapperApi - fetch parameter creator
 * @export
 */
export const YrNoWeatherWrapperApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} path 
         * @param {string} [userAgentString] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yrNoWeatherWrapperGet(path: string, userAgentString?: string, cache?: number, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling yrNoWeatherWrapperGet.');
            }
            const localVarPath = `/vcr/api/yrnoapiwrapper/get/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAgentString !== undefined) {
                localVarQueryParameter['userAgentString'] = userAgentString;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YrNoWeatherWrapperApi - functional programming interface
 * @export
 */
export const YrNoWeatherWrapperApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} path 
         * @param {string} [userAgentString] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yrNoWeatherWrapperGet(path: string, userAgentString?: string, cache?: number, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = YrNoWeatherWrapperApiFetchParamCreator(configuration).yrNoWeatherWrapperGet(path, userAgentString, cache, authorization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * YrNoWeatherWrapperApi - factory interface
 * @export
 */
export const YrNoWeatherWrapperApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} path 
         * @param {string} [userAgentString] 
         * @param {number} [cache] 
         * @param {string} [authorization] Bearer token in JWT format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yrNoWeatherWrapperGet(path: string, userAgentString?: string, cache?: number, authorization?: string, options?: any) {
            return YrNoWeatherWrapperApiFp(configuration).yrNoWeatherWrapperGet(path, userAgentString, cache, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * YrNoWeatherWrapperApi - object-oriented interface
 * @export
 * @class YrNoWeatherWrapperApi
 * @extends {BaseAPI}
 */
export class YrNoWeatherWrapperApi extends BaseAPI {
    /**
     * 
     * @param {string} path 
     * @param {string} [userAgentString] 
     * @param {number} [cache] 
     * @param {string} [authorization] Bearer token in JWT format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YrNoWeatherWrapperApi
     */
    public yrNoWeatherWrapperGet(path: string, userAgentString?: string, cache?: number, authorization?: string, options?: any) {
        return YrNoWeatherWrapperApiFp(this.configuration).yrNoWeatherWrapperGet(path, userAgentString, cache, authorization, options)(this.fetch, this.basePath);
    }

}

